import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebar from '../dashboardsidebar';
import $, { event } from 'jquery';
// import { useNavigate } from "react-router-dom";

const APP_URL = "https://www.admin.diaclabs.com/";


function EditPage() {
    const [mess, setMess] = useState('')
    const [registeras, setCompany] = useState('')
    const [company_name, setcompany_name] = useState('')
    const [url, seturl] = useState('')
    const [password, setPass] = useState('')
    const [rePass, setRepass] = useState('')
    const [mobileno, setMblNo] = useState('')
    const [gst, setGstNo] = useState('')
    const [alternateno, setalternateno] = useState('')
    const [industries, setindustries] = useState('')
    const [companytype, setCompnyType] = useState('')
    const [information, setSourOfInfo] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [pincode, setPincode] = useState('')
    const [city, setCity] = useState('')
    const [districk, setdistrick] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')

    const [firstname, setFirstname] = useState("")
    const [email, setemail] = useState("")
    const [data, setdata] = useState('')
    const [token, settoken] = useState('')
    // setdata('[]');
    let navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token")
        console.log(token, "token for cart")
        const url = APP_URL + 'userProfile'
        console.log(url);
        console.log(APP_URL);
        settoken(token)

        fetch(APP_URL + 'userProfile', {
            headers: {
                Authorization: `${token}`
            }
        }).then((res) => res.json()).then((data) => {

            // console.log(data,"value of cart")
            console.log(data, 'data')
            setdata(data.message[0])
            const lvlup = data.message.filter((item) => {
                localStorage.setItem('name', item.firstname)
                console.log(item.firstname);
            })
        })

    }, [])
    // 

    function userProfileEditPost() {
       event.preventDefault();
        // alert('submitted');
        var token = localStorage.getItem('token');
        var myHeaders = new Headers();
        myHeaders.append("authorization", token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", data.email);
        urlencoded.append("authorization", token);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(APP_URL + "userEmailEditPost", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        navigate("/userProfile");
    }

    return (
        <>

            <div className="user-profile">
                <form action="" id="userProfileEditPost">
                    <input type="hidden" name="token" value={token} />
                    <div className="container py-4 d-flex align-items-start">
                        <Sidebar />

                        <div className="row">

                            <div className="col-md-12 mt-4">
                                <div className="form_group_1">
                                    <label className="form__label">
                                        Email: <sup>*</sup>
                                    </label>
                                    <i className="fa fa-envelope" />
                                    <input
                                        type="text"
                                        name="emailReg"
                                        placeholder="Enter Your Email"
                                        className="form_group_select_box"
                                        // readOnly={email}
                                        id="email"
                                        // disabled
                                        value={data.email}
                                        onChange={(e) => setdata({ ...data, email: e.target.value })}

                                        // onChange={(e)=>setEmail(e.target.value)}

                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 py-4">
                                <button className="btn btn-dark py-1 w-100" onClick={userProfileEditPost}>Save</button>
                            </div>
                        </div>

                        <div className="row">

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditPage;