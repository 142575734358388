import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';
import Sidebar from '../dashboardsidebar';
import './css.css';
import styles from './css.css';
import $ from 'jquery';
import InvoicePdf from './invoicePdf';
import EasebuzzCheckoutButton from './EasebuzzCheckout2'; // Adjust the path accordingly
import logoLeft from '../images/Logo_pcb.png'
const APP_URL = "https://www.admin.diaclabs.com/";
const APP_URL2 = "https://www.admin.diaclabs.com/";
function Invoice() {
    const navigate = useNavigate()

    const [profile, setProfile] = useState([])
    const [OrderData, setOrderData] = useState([])
    const [StencilCartData, setStencilCartData] = useState([])
    const [AssemblyCatData, setAssemblyCatData] = useState([])
    const [LayoutCartData, setLayoutCartData] = useState([])
    const [Order_exception, setOrder_exception] = useState([])
    const [ExceptionMsg, setExceptionMsg] = useState([])

    const token = localStorage.getItem("token")
    useEffect(() => {
        const token = localStorage.getItem("token")
        // console.log(token, "token for cart")
        // const url = APP_URL + 'userProfile'

        const queryParams = new URLSearchParams(window.location.search)
        const order_id = queryParams.get("order_id")
        // console.log('order_id ::'+order_id);

        var myHeaders = new Headers();
        myHeaders.append("authorization", token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(APP_URL + "OrderDetail/" + order_id, requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
                // console.log('order_exception:::::::'.orderResult.order_exception);
                setOrder_exception(orderResult.order_exception);
                setOrderData(orderResult.OrderData[0]);
                setLayoutCartData(JSON.parse(orderResult.OrderData[0].LayoutCartData));
                setStencilCartData(JSON.parse(orderResult.OrderData[0].StencilCartData));
                setAssemblyCatData(JSON.parse(orderResult.OrderData[0].AssemblyCatData));

            })
            .catch((error) => console.log("error", error));


        const handlePrint = () => {
            const divContents = document.getElementById('dvContainer').innerHTML;
            const printWindow = window.open('', '', 'height=400,width=800');

            printWindow.document.write('<html><head><title>Invoice Print</title>');
            printWindow.document.write('</head><body >');
            printWindow.document.write('<div style="text-align:center; margin-bottom: 12px;"><img src="' + logoLeft + '" alt="" style="width: 259px;margin: auto;"></div>');
            printWindow.document.write(divContents);
            printWindow.document.write('</body></html>');
            printWindow.document.write(styles);
            printWindow.document.write(`
      <style>
      body {
        font-family: Arial, sans-serif;
      }
       .clearfix {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 19px;
      }
      .clearfix {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 19px;
      }
      .clearfix>small {
        font-size: 11px;
      }
      #ebz-checkout-btn{
        display:none
      }
      </style>
    `);
            printWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css">');

            printWindow.document.close();
            printWindow.print();
        };

        const printButton = document.getElementById('btnPrint');
        printButton.addEventListener('click', handlePrint);

        return () => {
            printButton.removeEventListener('click', handlePrint);
        };



    }, [])
    console.log("Order_exception :: " + JSON.stringify(Order_exception));
    // console.log(StencilCartData);
    // console.log("AssemblyCatData :: "+AssemblyCatData);





    function Handler(event) {
        event.preventDefault();
        const token1 = localStorage.getItem("token");
        var attachment = document.getElementById("attachment"); // Replace "fileInput" with the actual ID of your file input element

        if (token1 != null) {
            var myHeaders = new Headers();
            myHeaders.append("authorization", token1);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new FormData();
            urlencoded.append("title", $("#title").val());
            urlencoded.append("message", $("#message").val());
            urlencoded.append("order_id", OrderData.id);
            urlencoded.append("attechment", attachment.files[0]);

            var settings = {
                "url": APP_URL2 + "user/OrderExceptionSave",
                "method": "POST",
                // "timeout": 0,
                "timeout": 15000,
                "cache": false,
                "processData": false,
                "mimeType": "multipart/form-data",
                "contentType": false,
                "data": urlencoded,
                "headers": {
                    "authorization": token1, // Replace "Bearer" with your token type if needed
                }
            };

            $.ajax(settings).done(function (response) {
                console.log(response);
                alert('Order Exception Save Successfully.');
                // naviagte('/dashboard');
                // navigate("/cart");
                window.location = ''
            });
        }
    }

    return (
        <>



            <div className="user-profile">

                <div className="container py-4 d-flex align-items-start" style={{ "flex-wrap": "wrap" }}>
                    {/* <Sidebar /> */}

                    {/* <iframe title="W3Schools Free Online Web Tutorials"> */}
                    <main className="main row" style={{ "width": "100%", "margin": '30px 0' }}>

                        <div className="col-md-4" style={{ "border-right": '1px solid' }}>
                            <h3>Order Exception</h3>
                            <button type="button" class="badge bg-primary" data-bs-toggle="modal"
                                data-bs-target="#order_exception">
                                Send Order Exception
                            </button>
                            <hr></hr>
                            {Order_exception.map((exc, index3) => {
                                const utcTime = new Date(exc.created_at);
                                const istTime = new Date(utcTime.getTime());
                                // const istTime = new Date(utcTime.getTime() + 5.5 * 60 * 60 * 1000);
                                // const options = { timeZone: 'Asia/Kolkata',date:'2-digit', hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit' };

                                const options = {
                                    timeZone: 'Asia/Kolkata',
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: true
                                };
                                const istTimeString = istTime.toLocaleString('en-IN', options);

                                return (
                                    <div className={`p-3 ${exc.writer === 'Admin' ? 'text-end' : ''}`} key={exc.id}>
                                        <b>Sender: </b> {exc.writer} /  <b> {istTimeString} </b> <br />
                                        <span>Title: </span>
                                        {exc.title} <br />
                                        <span>Attachment: <a href={APP_URL + `/image/${exc.attechment}`}>Click to View</a></span>
                                        <br />
                                        <span>Message: <span>{exc.message}</span></span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="col-md-8" id="dvContainer">
                            <form id="form1" className="d-none" >
                                <input type="button" value="Download Invoice" id="btnPrint" />
                            </form>

                            <a href={APP_URL + `invoiceDownload/` + OrderData.order_id} ><button type="button" class="btn btn-primary">Download Invoice</button></a>
                            {/* <a href="" class="btn btn-info p-2 ">Download Invoice</a> */}
                            {/* <InvoicePdf /> */}
                            <h1 class="clearfix"><small><span>Date</span>: {OrderData.created_at}</small> INVOICE <small>   </small> </h1>
                            Order No : <b> {OrderData.id} </b><br />
                            Payment Status : <span style={{ color: 'red' }}> {OrderData.payment_status} </span> <br></br>
                            Status : <span style={{ color: 'red' }}>{OrderData.status}</span>
                            {OrderData.payment_status === 'Pending' || OrderData.payment_status === 'Processed' ? (
                                <EasebuzzCheckoutButton />
                            ) : (
                                <>
                                    {/* <p>Test</p> */}
                                </> 
                            )
                            }

                            {/* {OrderData.StencilCartData} */}

                            {LayoutCartData.length > 0 ? (
                                <>
                                    <h5 className="mt-4">PCB Layout</h5>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="service">Service</th>
                                                <th className="desc">PCB Name</th>
                                                <th className="desc">Components</th>
                                                <th className="desc">Lead Time</th>
                                                <th className="desc">Price</th>
                                                <th className="desc">Shipment Date</th>
                                                <th className="desc">Schematic</th>
                                                <th className="desc">Bill of Materials</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {LayoutCartData.map((Layout, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="service">PCB Layout</td>
                                                        <td className="service">{Layout.pcb_name}</td>
                                                        <td className="service">{Layout.components}</td>
                                                        <td className="service">{Layout.lead_time}</td>
                                                        <td className="service">{Layout.total_price_with_tex}</td>
                                                        <td className="service">{Layout.shipment_date}</td>
                                                        <td className="service">
                                                            <a href={APP_URL + 'image/' + Layout.schematic}><button type="button" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>
</button></a>
                                                        </td>
                                                        <td className="service">
                                                            <a href={APP_URL + 'image/' + Layout.bill_of_materials}><button type="button" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i>
</button></a>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                ''
                            )}
                            {StencilCartData.length > 0 ? (
                                <>
                                    <hr></hr>
                                    <h3 className="mt-4">Stencil</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="service">Service</th>
                                                <th class="desc">PCB Name</th>
                                                <th class="desc">Components</th>
                                                <th class="desc">Lead Time</th>
                                                <th>Price</th>
                                                <th>Shipment Date</th>
                                                <th>Schematic</th>
                                                <th>Bill of Materials</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {StencilCartData.map((Stencil, index) => {
                                                return (
                                                    <tr>
                                                        <td class="service">Stencil</td>
                                                        <td class="service">{Stencil.pcb_name}</td>
                                                        <td class="service">{Stencil.components}</td>
                                                        <td class="service">{Stencil.lead_time}</td>
                                                        <td class="unit">{Stencil.total_price_with_tex}</td>
                                                        <td class="unit">{Stencil.shipment_date}</td>
                                                        <td class="unit"><a href={APP_URL + 'image/' + Stencil.schematic}>Click to View</a></td>
                                                        <td class="unit"><a href={APP_URL + 'image/' + Stencil.bill_of_materials}>Click to View</a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                ''
                            )}
                            {AssemblyCatData.length > 0 ? (
                                <>                        <hr></hr>
                                    <h3 className="mt-4">PCB Assembly</h3>
                                    <div className="Assembly01">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="service">Service</th>
                                                <th class="desc">PCB Name</th>
                                                <th class="desc">No of Layers</th>
                                                <th class="desc">No of board</th>
                                                <th>Price</th>
                                                {/* <th>Shipment Date</th> */}
                                                <th>Gerber Data</th>
                                                <th>Bill of Materials</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {AssemblyCatData.map((Assembly, index) => {
                                                return (
                                                    <tr>
                                                        <td class="service">PCB Assembly</td>
                                                        <td class="service">{Assembly.pcb_name}</td>
                                                        <td class="service">{Assembly.no_of_layers}</td>
                                                        <td class="service">{Assembly.pcb_thickness}</td>
                                                        <td class="service">{Assembly.price}</td>
                                                        {/* <td class="unit">{Assembly.shipment_date}</td> */}
                                                        <td class="service"><a href={APP_URL + 'image/' + Assembly.gerber_data}><button type="button" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i></button></a></td>
                                                        <td class="service"><a href={APP_URL + 'image/' + Assembly.bill_of_materials}><button type="button" class="btn btn-success"><i class="fa fa-eye" aria-hidden="true"></i></button></a></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    </div>
                                </>
                            ) : (
                                ''
                            )}
                            {/* <div id="details" class="clearfix">
                            <div id="project">
                                <div class="arrow"><div class="inner-arrow"><span>PROJECT</span> Website development</div></div>
                                <div class="arrow"><div class="inner-arrow"><span>CLIENT</span> John Doe</div></div>
                                <div class="arrow"><div class="inner-arrow"><span>ADDRESS</span> 796 Silver Harbour, TX 79273, US</div></div>
                                <div class="arrow"><div class="inner-arrow"><span>EMAIL</span> <a href="mailto:john@example.com">john@example.com</a></div></div>
                            </div>
                            <div id="company">
                                <div class="arrow back"><div class="inner-arrow">Company Name <span>COMPANY</span></div></div>
                                <div class="arrow back"><div class="inner-arrow">455 Foggy Heights, AZ 85004, US <span>ADDRESS</span></div></div>
                                <div class="arrow back"><div class="inner-arrow">(602) 519-0450 <span>PHONE</span></div></div>
                                <div class="arrow back"><div class="inner-arrow"><a href="mailto:company@example.com">company@example.com</a> <span>EMAIL</span></div></div>
                            </div>
                        </div> */}
                            {/* <div id="notices">
                            <div>NOTICE:</div>
                            <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
                        </div> */}
                        </div>

                    </main>
                    {/* <footer>
                        Invoice was created on a computer and is valid without the signature and seal.
                    </footer> */}
                    {/* </iframe> */}



                </div>


            </div>
            <div className="modal fade" id="order_exception" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Send Order Exception</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form action="#" method="post">
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Title</label>
                                    <input type="text" className="form-control" name="title" id="title" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                                    <textarea className="form-control" name="message" id="message" cols="30" rows="4"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Attachment</label>
                                    <input type="file" className="form-control" name="attachment" id="attachment" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="badge bg-warning" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="badge bg-success" onClick={Handler}>Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Invoice;