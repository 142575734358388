// import React from "react";
import { Link, json, useNavigate } from "react-router-dom"
import { useState } from "react"
const APP_URL = "https://www.admin.diaclabs.com/";


function EmailRegister() {
  const navigate = useNavigate()

  // const [firstname, setFirstname] = useState('')
  // const [lastname, setLastname] = useState('')
  // const [email, setEmail] = useState('')

  const [mess, setMess] = useState('')
  const [registeras, setCompany] = useState('')
  const [company_name, setcompany_name] = useState('')
  const [url, seturl] = useState('')
  const [password, setPass] = useState('')
  const [rePass, setRepass] = useState('')
  const [mobileno, setMblNo] = useState('')
  const [gst, setGstNo] = useState('')
  const [alternateno, setalternateno] = useState('')
  const [industries, setindustries] = useState('')
  const [companytype, setCompnyType] = useState('')
  const [information, setSourOfInfo] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [pincode, setPincode] = useState('')
  const [city, setCity] = useState('')
  const [districk, setdistrick] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')

  const queryParams = new URLSearchParams(window.location.search)
  const firstname = queryParams.get("firstName")
  const lastname = queryParams.get("lastName")
  const email = queryParams.get("email")
  // console.log(firstname, lastname, email)

  function handleForm(e) {
    const reg = localStorage.getItem("useryoutube")
    e.preventDefault()
    const formData = {firstname,lastname,email, registeras, company_name, url, password, mobileno, rePass, gst, alternateno, industries, companytype, information, address1, address2, pincode, city, districk, state, country }
    console.log('FormData : '+formData)
    fetch(APP_URL + 'finalRegistration', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData)
    })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        console.log('data:::'+ JSON.stringify(data))
        if (data.message === "registration successfull") {
          navigate('/login')
        } else {
          setMess('Something Went Wrong Please try Again Leter')
        }

        //   if(loginuser.length===0){
        //     alert("nooooooo")
        //   } else{
        //     alert("ok")
        //     localStorage.setItem("login",JSON.stringify([loginuser]))
        //   }
        // }


        // navigate("/dashboard")

        //  console.log(res);
      }).catch((eroor) => {
        console.log(eroor.message);

      })

    // .then((data) => {
    //     console.log(data)
    //     if(data.message==="registration successfull"){
    //       navigate('/login')
    //     } else{
    //         setMess('Something Went Wrong Please try Again Leter')
    //     }

    // })
    // console.log(formData)
  }

  return (
    <>
      <section className="container-fluid py-4">
        <form method='post' onSubmit={(e) => { handleForm(e) }}>
          <div className="container reg_form_main_cum">
            <div className="row">
              <div className="reg_form_main">
                <h3>Registration</h3>
                <div className="col-md-6 mt-4">
                  <div className="form_group_1">
                    <label className="form__label">
                      Register As: <sup>*</sup>
                    </label>
                    <i className="fa fa-briefcase i-top" />
                    <select name={909} className="form_group_select_box" onChange={(e) => setCompany(e.target.value)}
                      value={registeras}
                      required
                    >
                      <option>Select a Company Types</option>
                      <option>Company</option>
                      <option>Government organization</option>
                      <option>Individual</option>
                      <option>Educational institution</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    First Name: <sup>*</sup>
                  </label>
                  <i className="fa fa-user" />
                  <input
                    type="text"
                    name="Fname_1"
                    placeholder="Enter Fname"
                    className="form_group_select_box"
                    value={firstname}
                    required
                  // onChange={(e)=>setFirstname(e.target.value)}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Last Name: <sup>*</sup>
                  </label>
                  <i className="fa fa-user" />
                  <input
                    type="text"
                    name="Lname_1"
                    placeholder="Enter Lname"
                    className="form_group_select_box"
                    value={lastname}
                    required
                  // onChange={(e)=>setLastname(e.target.value)}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Company Name: <sup>*</sup>
                  </label>
                  <i className="fa fa-building" />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Enter Your Company Name"
                    className="form_group_select_box"
                    onChange={(e) => setcompany_name(e.target.value)}
                    value={company_name}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">Company URL:</label>
                  <i className="fa fa-link" />
                  <input
                    type="text"
                    name="CompURL"
                    placeholder="Enter Your Company URL"
                    className="form_group_select_box"
                    onChange={(e) => seturl(e.target.value)}
                    value={url}
                    

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Email: <sup>*</sup>
                  </label>
                  <i className="fa fa-envelope" />
                  <input
                    type="text"
                    name="emailReg"
                    placeholder="Enter Your Email"
                    className="form_group_select_box"
                    // readOnly={email}
                    value={email}
                    required
                  // onChange={(e)=>setEmail(e.target.value)}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Password: <sup>*</sup>
                  </label>
                  <i className="fa fa-lock" />
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    className="form_group_select_box"
                    onChange={(e) => setPass(e.target.value)}
                    value={password}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Re-Enter Password: <sup>*</sup>
                  </label>
                  <i className="fa fa-lock" />
                  <input
                    type="password"
                    name="confirmpass"
                    placeholder="Re-Enter Your Password"
                    className="form_group_select_box"
                    onChange={(e) => setRepass(e.target.value)}
                    value={rePass}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Mobile Nr: <sup>*</sup>
                  </label>
                  <i className="fa fa-phone" />
                  <input
                    type="text"
                    name="mobilenr"
                    placeholder="Enter Mobile Nr"
                    className="form_group_select_box"
                    onChange={(e) => setMblNo(e.target.value)}
                    value={mobileno}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Alternate Nr: <sup>*</sup>
                  </label>
                  <i className="fa fa-phone" />
                  <input
                    type="text"
                    name="alternr"
                    placeholder="Enter Alternate Nr"
                    className="form_group_select_box"
                    onChange={(e) => setalternateno(e.target.value)}
                    value={alternateno}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    GST Nr: <sup>*</sup>
                  </label>
                  <i className="fa fa-check" />
                  <input
                    type="text"
                    name="gst"
                    placeholder="Enter GST Nr"
                    className="form_group_select_box"
                    onChange={(e) => setGstNo(e.target.value)}
                    value={gst}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    industries: <sup>*</sup>
                  </label>
                  <i className="fa fa-industries i-top" />
                  <select name={909} className="form_group_select_box" onChange={(e) => setindustries(e.target.value)}
                    value={industries}
                  >
                    <option>Select an industries</option>
                    <option>Aerospace</option>
                    <option>Automotive Electronics</option>
                    <option>Consumer Electronics</option>
                    <option>Defense</option>
                    <option>Educational Institute</option>
                    <option>Electronics Manufacturing Services</option>
                    <option>Energy and Utilities</option>
                    <option>Hobbyist</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Company Type: <sup>*</sup>
                  </label>
                  <i className="fa fa-briefcase i-top" />
                  <select name={909} className="form_group_select_box" onChange={(e) => setCompnyType(e.target.value)}
                    value={companytype}
                  >
                    <option>Select a Company Type</option>
                    <option>Standard</option>
                    <option>Special Economic Zone</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Source of Information: <sup>*</sup>
                  </label>
                  <i className="fa fa-bullhorn i-top" />
                  <select name={909} className="form_group_select_box" onChange={(e) => setSourOfInfo(e.target.value)}
                    value={information}
                  >
                    <option>Select a Source of Information</option>
                    <option>Just dial</option>
                    <option>Magazine</option>
                    <option>Search engine</option>
                    <option>word of mouth</option>
                    <option>Others</option>
                    <option>Exhibition</option>
                    <option>India mart</option>
                    <option>Social Media</option>
                  </select>
                </div>
              </div>
            </div>
            <h4 className="mt-4">Address</h4>
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Address Line 1: <sup>*</sup>
                  </label>
                  <i className="fa fa-map-signs" />
                  <input
                    type="text"
                    name="add1"
                    placeholder="Enter Address Line 1"
                    className="form_group_select_box"
                    onChange={(e) => setAddress1(e.target.value)}
                    value={address1}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">Address Line 2:</label>
                  <i className="fa fa-map-signs" />
                  <input
                    type="text"
                    name="add2"
                    placeholder="Enter Address Line 2"
                    className="form_group_select_box"
                    onChange={(e) => setAddress2(e.target.value)}
                    value={address2}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Pincode: <sup>*</sup>
                  </label>
                  <i className="fa fa-map-pin" />
                  <input
                    type="number"
                    name="Pincode"
                    placeholder="Enter Pincode"
                    className="form_group_select_box"
                    onChange={(e) => setPincode(e.target.value)}
                    value={pincode}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    City: <sup>*</sup>
                  </label>
                  <i className="fa fa-map-marker" />
                  <input
                    type="text"
                    name="New_city"
                    placeholder="Enter City"
                    className="form_group_select_box"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                  District: <sup>*</sup>
                  </label>
                  <i className="fa fa-map-marker" />
                  <input
                    type="text"
                    name="New_districk"
                    placeholder="Enter District"
                    className="form_group_select_box"
                    onChange={(e) => setdistrick(e.target.value)}
                    value={districk}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    State: <sup>*</sup>
                  </label>
                  <i className="fa fa-map i-top" />
                  <select name={909} className="form_group_select_box" onChange={(e) => setState(e.target.value)}
                    value={state}
                  >
                    <option value="">Select state</option>
                    <option value="AN">Andaman and Nicobar Islands</option>
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BR">Bihar</option>
                    <option value="CH">Chandigarh</option>
                    <option value="CT">Chhattisgarh</option>
                    <option value="DN">Dadra and Nagar Haveli</option>
                    <option value="DD">Daman and Diu</option>
                    <option value="DL">Delhi</option>
                    <option value="GA">Goa</option>
                    <option value="GJ">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="LA">Ladakh</option>
                    <option value="LD">Lakshadweep</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ML">Meghalaya</option>
                    <option value="MZ">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OR">Odisha</option>
                    <option value="PY">Puducherry</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TG">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="UT">Uttarakhand</option>
                    <option value="WB">West Bengal</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Country: <sup>*</sup>
                  </label>
                  <i className="fa fa-globe i-top" />
                  <input
                    type="text"
                    name="New_Country"
                    placeholder="India"
                    className="form_group_select_box"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <button
                  id="selectRegister"
                  type="submit"
                  className="button_1 button--promo"
                >
                  <span>Sign up</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>

    </>
  );
}

export default EmailRegister;