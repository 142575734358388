import React from "react";
import ServiceHeader from "./ServiceHeader";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

import Reg2 from "../Reg2";
import { all } from "axios";
// import { Navigate, useNavigate } from "react-router-dom";

import { Link, Navigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const APP_URL2 = "https://www.admin.diaclabs.com/";
const Pcbfabrication = () => {
  const naviagte = useNavigate();
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setshow] = useState(false);

  const [layer, setlayer] = useState([]);
  const [options, setoptions] = useState([]);
  const [data, setData] = useState([]);
  // console.log(layer,"this is real la yer ")
  const [components, setComponents] = useState([]);

  let navigate = useNavigate();
  var token = localStorage.getItem('token');

  const [GerberData, setGerberData] = useState(null);

  const GerberDataChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'zip' || fileExtension == 'rar'){
      setGerberData(file);
    }else{
      setGerberData(null);
      alert("Please Select zip or rar File Only.")
    }
    // setGerberData(file);
  };
  const handleDeleteFile = () => {
    // Remove the selected file
    setGerberData(null);
  };
  const handleGerberFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setGerberData(droppedFile);
    // alert(selectedFile.name);
  };
  
  useEffect(() => {
    const apiUrl = APP_URL2 + "pcb_fabrication_get_data"; // Replace this with your API endpoint URL
    console.log(apiUrl);
    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setData(data?.data);
        setlayer(data?.data);
        // GetPricing();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
      GetNote();
  }, []);


  const handlesubmit = (e) => {
    
    e.preventDefault();
    var fileInput = document.getElementById("fileInput"); // Replace "fileInput" with the actual ID of your file input element
    if($("#pcb_name").val() == ''){
      alert('PCB Name is Required.');
      
    }else if(fileInput.files[0] == '' || !fileInput.files[0]){
      alert('Gerber Data is Required.');
    }
    else
    if (token != null && token != '' ) {
      // var fileInput = document.getElementById("material"); // Replace "fileInput" with the actual ID of your file input element
      // alert($('#pcb_name').val());

      var form = new FormData();
      form.append("material", fileInput.files[0]);

      form.append("token", token);
      form.append("pcb_name", $('#pcb_name').val());
      form.append("layers", $('#layers').val());
      form.append("pcb_thickness", $('#pcb_thickness').val());
      
      form.append("top_solder_mask", $('#top_solder_mask').val());
      form.append("top_legend", $('#top_legend').val());
      form.append("tg", $('#tg').val());
      form.append("via_fill", $('#via_fill').val());
      form.append("carbon_contacts", $('#carbon_contacts').val());
      form.append("blind_buried_Via", $('#blind_buried_Via').val());
      // form.append("material", $('#material').val());
      form.append("surface_finish", $('#surface_finish').val());
      form.append("finish_cu_thickness_micron", $('#finish_cu_thickness_micron').val());
      form.append("bottom_solder_mask", $('#bottom_solder_mask').val());
      form.append("Hard_Gold", $('#Hard_Gold').val());
      form.append("Electrical_Testing", $('#Electrical_Testing').val());
      form.append("Serialization_for_PCBs", $('#Serialization_for_PCBs').val());
      form.append("Special_buildup", $('#Special_buildup').val());
      form.append("UL_Logo", $('#UL_Logo').val());
      form.append("Impedance_Control", $('#Impedance_Control').val());
      form.append("Round_Edge_Platting", $('#Round_Edge_Platting').val());
      form.append("Gold_Area", $('#Gold_Area').val());
      
      // console.log(form.get('token'));
      // form.forEach((value, key) => {
      //   console.log(key, value);
      // });
      form.append("panel_required", $('#panel_required').val());
      form.append("panel_dimensions", $('#panel_dimensions').val());
      // form.append("panel_qty", $('#panel_qty').val());
      form.append("panel_quantity", $('#panel_quantity').val());

      var settings = {
        "url": APP_URL2+"store_pcb_fabrication_enquiry",
        "method": "POST",
        // "timeout": 0,
        "timeout": 15000,
        "cache": false,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": form
      };
      $('#Loader').show();
      $.ajax(settings).done(function (response) {
        $('#Loader').hide();
        console.log(response);
        alert('Enquiry Successfully Submitted.');
        naviagte('/dashboard');
      });

    } else {
      naviagte('/login');
    }

  };
  // function Handler() {
  //   const token = localStorage.getItem("token");
  //   if (token != null) {
  //     navigate("/chekout");
  //   } else {
  //     navigate("/login");
  //   }
  // }

  
  const [Note, setNote] = useState(""); // Initializing state for storing fetched data
  function GetNote() {
    const apiUrl = APP_URL2 + "Services_Notes_data_fetch_api/PCB_Fabrication";
    console.log(apiUrl);

    fetch(apiUrl)
      .then((response) => {
        console.log('REsponse :::::: ' + response); // Log the entire response
        return response.json();
      })
      .then((data) => {
        setNote(data?.data.contant);
        setLoading(false);
        console.log("Successfully Data Fetch :::: ", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", JSON.stringify(error));
        setLoading(false);
      });
  }

  return (
    <div id="servicemain">
      <div className="container-fluid py-5 service">
        <div className="row">
          <ServiceHeader />
          <form className="row" onSubmit={handlesubmit}>
            <div className="col-lg-10 m-auto">
              <div className="layout_tabs_main mb-4">
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <div className="layout_tabs_main_chile">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          PCB Name: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="Layer_custum_css"
                          id="pcb_name"
                          placeholder="PCB Name"
                          aria-describedby="emailHelp" required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 model" id="#exampleModal">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    >
                      PCB Dimensions(mm): <sup>*</sup>
                    </label>
                    <div className="Dimensions_input d-flex align-items-center">
                      <input type="text" defaultValue={5} name="f" />
                      <span>X</span>
                      <input type="text" defaultValue={6} name="ff" />
                      <span>MM</span>
                    </div>
                  </div>

                  <div className="col-lg-4 mt-3 ">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Panel Required: <sup>*</sup>
                    </label>
                    <br />
                    <div className="radio-button">
                      <input
                        type="radio"
                        name="radio52"
                        id="radioacl"
                        defaultChecked=""
                        onClick={() => setshow(true)}
                      />
                      <label htmlFor="radioacl">No</label>
                      <>
                        <input
                          type="radio"
                          name="radio52"
                          id="radiobl"
                          data-bs-toggle="fim"
                          data-bs-target="#exampleModal"
                          onClick={() => setshow(true)}
                        />
                        <label htmlFor="radiobl">Yes</label>
                      </>
                    </div>
                  </div>

                  {show && (
                    <>

                      <div className="col-lg-4" >
                        <label htmlFor="exampleInputEmail1" className="form-label">
                          Panel Dimensions: <sup>*</sup>
                        </label>
                        <div className="Dimensions_input d-flex align-items-center">
                          <input type="text" defaultValue={5} name="f" />
                          <span>X</span>
                          <input type="text" defaultValue={6} name="ff" />
                          <span>MM</span>
                        </div>
                      </div>

                      <div className="col-lg-4" id="#exampleModal">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                          Panel Quantity: <sup>*</sup>
                        </label>
                        <div className="Dimensions_input d-flex align-items-center">
                        <input
                          type="text"
                          className="Layer_custum_css w-100"
                          id="panel_quantity"
                          placeholder="PCB Quantity"
                          aria-describedby="emailHelp"
                        />
                        </div>
                      </div>
                    </>
                  )}





                  {layer.map((item, index) => {


                    if (item != null) {


                      return (

                        <div className="col-sm-4 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              {/* {JSON.stringify(item)} */}
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                {item.object}: <sup>*</sup>
                              </label>


                              {item.type == 'input' ?
                                (<input
                                  type="text"
                                  className="Layer_custum_css"
                                  placeholder={item.object}
                                  id={item.slug}
                                  aria-describedby="emailHelp"
                                  required
                                />) : (

                                  <select className="w-100 Layer_custum_css" name={item.slug} id={item.slug} required>
                                    {item.options.map((option, optionIndex) => (
                                      <option key={optionIndex}>{option.option_name}</option>
                                    ))}

                                  </select>

                                )

                              }





                            </div>
                          </div>

                        </div>



                      );
                    }

                  })}
                  <div className="col-sm-4 mb-3">
                    <div className="layout_tabs_main_chile" onDrop={handleGerberFileDrop}
                      onDragOver={(event) => event.preventDefault()}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Gerber Data: <sup>*</sup>
                        </label>
                        <div id="wrapper" className="dropify-wrapper">
                          <input
                            type="file"
                            name="material"
                            id="fileInput"
                            className="form-control"
                            accept=".rar,.zip"
                            onChange={GerberDataChange}
                          />
                          <div className="dropify-message">
                            <div className="dropify-message-1">
                              <svg
                                width="18px"
                                height="18px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                              </svg>
                              <p>Drag and drop a file here or click <b style={{color:'#33b751'}}>File format: .rar / .zip </b></p>
                            </div>
                            {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {GerberData && (
                        <div>
                          <p className="m-0">Selected File: <b> {GerberData.name} </b> 
</p>
                          <button onClick={handleDeleteFile} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>
                          {/* <p className="m-0">File Size:<b>  {GerberData.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {GerberData.type} </b></p> */}
                        </div>
                      )}
                  </div>
                  <p style={{"background": "#d8d8e1","border-radius": "10px" ,"padding": "20px"}}><i class="fa fa-bullhorn" aria-hidden="true" style={{"font-size": "20px","color":" rgb(51, 183, 81)"}}></i> {Note}</p>

                  <div className="col-lg-4 mt-5" id="fabr">
                    <li className="list-itemss">

                      {/* <button
                        className="buttonss"

                      onClick={Handler}
                      >Multifly Factor :
                      Submit Your Enquiry
                      </button> */}
                      {token != null ?
                        // <a href="#" > Place inquiry</a>
                        <button className="button button--promo" onClick={handlesubmit} >
                          Submit Your Enquiry
                        </button>
                        :
                        <a href="/login" className="button button--promo"> Please Login to place an inquiry</a>
                      }
                    </li>
                  </div>

                </div>

              </div>



            </div>
            {/* <div className="col-lg-4">
              <div className="layout_tabs_main">
                <div className="calculated_price_heading">
                  <h5 className="text-center w-100 text-green fontw-600 mb-0">
                    Calculated Price (₹)
                  </h5>
                </div>
                <div className="stencil_calculated_price calculated_price_padding">
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Service</label>
                    <label className="price-content price-title">
                      PCB Fabrication Flex
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Lead Time:</label>
                    <label id="PriceLeadTime" className="price-content">
                      7 WD
                    </label>
                  </div>
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Quantity:</label>
                    <label id="CalpcbQty" className="price-content">
                      10
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Unit Price:</label>
                    <label id="FabUnitPrice" className="price-content">
                      148
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Order Price:</label>
                    <label id="FabOrderPrice" className="price-content">
                      1480
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Tax(%):</label>
                    <label id="FabTax" className="price-content">
                      18
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Total Price:</label>
                    <label
                      id="PcbTotalPrice"
                      className="total-price price-content"
                    >
                      1746
                    </label>
                  </div>{" "}
                  <hr className="price-hr" />
                  <div className="bottom-10"></div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Maximum Sq Meter:</label>{" "}
                    <label className="price-content">8</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Your Sq Meter:</label>
                    <label id="YourSqmeter" className="price-content">
                      0.00
                    </label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Setup Charges:</label>
                    <label className="price-content total-price">Free</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Delivery Charges:</label>{" "}
                    <label className="price-content total-price">Free</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">Electrical Testing:</label>
                    <label className="price-content total-price">Free</label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <label className="price-title">
                      Tentative Shipment Date:
                    </label>{" "}
                    <label className="price-content total-price">
                      23/08/2023
                    </label>
                  </div>
                  <div className="d-flex justify-content-end fix-validation-text">
                    <label className="text-red" style={{ fontSize: 10 }}>
                      * WD = Working Days
                    </label>
                  </div>
                </div>
                <hr className="price-hr" />
                <div className="text-center bottom-10 d-flex justify-content-center align-items-center">
                  <a type="submit" className="button button--green top-20u">
                    <span>Compare prices</span>
                  </a>
                </div>
              </div>
            </div> */}
          </form>
          {/* <form>

            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="layout_tabs_main mb-4">
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCB Name: <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="exampleInputtext"
                            placeholder="PCB Name"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>


                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCB Layer: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Layer 1</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCB Thickness (mm): <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 model" id="#exampleModal">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        PCB Dimensions(mm): <sup>*</sup>
                      </label>
                      <div className="Dimensions_input d-flex align-items-center">
                        <input type="text" defaultValue={5} name="f" />
                        <span>X</span>
                        <input type="text" defaultValue={6} name="ff" />
                        <span>MM</span>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-3 offset-2">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Panel Required: <sup>*</sup>
                      </label>
                      <br />
                      <div className="radio-button">
                        <input
                          type="radio"
                          name="radio52"
                          id="radioacl"
                          defaultChecked=""
                        />
                        <label htmlFor="radioacl">No</label>

                        <input
                          type="radio"
                          name="radio52"
                          id="radiobl"
                          data-bs-toggle="fim"
                          data-bs-target="#exampleModal"
                        />
                        <label htmlFor="radiobl">Yes</label>
                      </div>
                    </div>
                    <div className="col-lg-6 model" id="#exampleModal">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Panel Dimensions(mm): <sup>*</sup>
                      </label>
                      <div className="Dimensions_input d-flex align-items-center">
                        <input type="text" defaultValue={5} name="f" />
                        <span>X</span>
                        <input type="text" defaultValue={6} name="ff" />
                        <span>MM</span>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCBs/Panel (QTY): <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="exampleInputtext"
                            placeholder="PCBs/Panel"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Top solider Mask: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Top Legend: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            TG: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Via Fill: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Carbon Contact: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Hard Gold: <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="exampleInputtext"
                            placeholder="Hard Gold"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Blind & Buried Via: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Electrical Testing: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Serialization for PCBs: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Special buildup: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Material: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Surface Finish: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div><div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Finish Cu Thickness micron(u): <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div><div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Bottom Solder Mask: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>0.06</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div><div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Bottom Legend: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            UL Logo: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Impedance Control: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option>Please Select</option>
                            <option>0.11</option>
                            <option>0.12</option>
                          </select>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Round Edge Platting: <sup>*</sup>
                          </label>
                          <select className="w-100 Layer_custum_css">
                            <option value={'yes'}>Yes</option>
                            <option value={'no'}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Gold Area (Approx) : <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="exampleInputtext"
                            placeholder=" Gold Area (Approx)"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Gerber Data: <sup>*</sup>
                          </label>
                          <div id="wrapper" className="dropify-wrapper">
                            <input
                              type="file"
                              name="f-img"
                              className="form-control"
                            />
                            <div className="dropify-message">
                              <div className="dropify-message-1">
                                <svg
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                </svg>
                                <p>Drag and drop a file here or click</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="add_to_card">
                        <button
                          type="submit"
                          className="btn btn-primary add-to-card"
                        >
                          Add to Card
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="layout_tabs_main">
                  <div className="calculated_price_heading">
                    <h5 className="text-center w-100 text-green fontw-600 mb-0">
                      Calculated Price (₹)
                    </h5>
                  </div>
                  <div className="stencil_calculated_price calculated_price_padding">
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Service</label>
                      <label className="price-content price-title">
                        PCB Fabrication Flex
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Lead Time:</label>
                      <label id="PriceLeadTime" className="price-content">
                        7 WD
                      </label>
                    </div>
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Quantity:</label>
                      <label id="CalpcbQty" className="price-content">
                        10
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Unit Price:</label>
                      <label id="FabUnitPrice" className="price-content">
                        148
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Order Price:</label>
                      <label id="FabOrderPrice" className="price-content">
                        1480
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Tax(%):</label>
                      <label id="FabTax" className="price-content">
                        18
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Total Price:</label>
                      <label
                        id="PcbTotalPrice"
                        className="total-price price-content"
                      >
                        1746
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="bottom-10"></div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Maximum Sq Meter:</label>{" "}
                      <label className="price-content">8</label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Your Sq Meter:</label>
                      <label id="YourSqmeter" className="price-content">
                        0.00
                      </label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Setup Charges:</label>
                      <label className="price-content total-price">Free</label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Delivery Charges:</label>{" "}
                      <label className="price-content total-price">Free</label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Electrical Testing:</label>
                      <label className="price-content total-price">Free</label>
                    </div>
                    <div className="d-flex justify-content-between">
                      <label className="price-title">
                        Tentative Shipment Date:
                      </label>{" "}
                      <label className="price-content total-price">
                        23/08/2023
                      </label>
                    </div>
                    <div className="d-flex justify-content-end fix-validation-text">
                      <label className="text-red" style={{ fontSize: 10 }}>
                        * WD = Working Days
                      </label>
                    </div>
                  </div>
                  <hr className="price-hr" />
                  <div className="text-center bottom-10 d-flex justify-content-center align-items-center">
                    <a type="submit" className="button button--green top-20u">
                      <span>Compare prices</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form> */}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Pcbfabrication;
