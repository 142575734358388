import React, { useEffect, useState } from "react";
import MyContext from "./mycontext";

function MyState(props) {
  const [show, setShow] = useState(false);

  return (
    <MyContext.Provider
      value={{
        show,
        setShow
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
}

export default MyState;
