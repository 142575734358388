import axios from "axios";
// import React, { useState } from "react";
import $ from 'jquery';
import React, { useState, useRef } from 'react';
import { useEffect } from "react";

import ServiceHeader from "./ServiceHeader";
import { Navigate, useNavigate } from "react-router-dom";
const APP_URL2 = "https://www.admin.diaclabs.com/";
const Componentsorcing = () => {

  const naviagte = useNavigate();
  const [name, setName] = useState("");
  var token = localStorage.getItem('token');

  const [Loading, setLoading] = useState(""); // Initializing state for storing fetched data
  
  useEffect(() => {
    GetNote();
  }, []);
  
  
  const [Note, setNote] = useState(""); // Initializing state for storing fetched data
  function GetNote() {
    const apiUrl = APP_URL2 + "Services_Notes_data_fetch_api/Component_Sourcing";
    console.log(apiUrl);

    fetch(apiUrl)
      .then((response) => {
        console.log('REsponse :::::: ' + response); // Log the entire response
        return response.json();
      })
      .then((data) => {
        setNote(data?.data.contant);
        setLoading(false);
        console.log("Successfully Data Fetch :::: ", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", JSON.stringify(error));
        setLoading(false);
      });
  }



  const [Materials, setMaterials] = useState(null);
  const MaterialsChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'xls' || fileExtension == 'xlsx'){
      setMaterials(file);
    }else{
      setMaterials(null);
      alert("Please Select Xls or Xlsx File Only.")
    }
    // alert(file);
  };

  const handleDeleteFile = () => {
    // Remove the selected file
    setMaterials(null);
  };

  const fileInputRef = useRef(null);

  const handlesubmit = (e) => {
    e.preventDefault();
    // alert(token);
    if (token != null && token != '') {
      // alert('Loged in');
      // const token = token;
      // alert(token);
      const pcb_name = $('#pcb_name1').val();
      // alert('text' + pcb_name);
      var fileInput = document.getElementById("fileInput"); // Replace "fileInput" with the actual ID of your file input element

      if (pcb_name != '' && fileInput.files[0]) {

        var form = new FormData();
        form.append("pcb_name", pcb_name);
        form.append("token", token);
        form.append("material", fileInput.files[0]);

        var settings = {
          "url": APP_URL2 + "enquiry_store_component_sourcing",
          "method": "POST",
          // "timeout": 0,
          "timeout": 15000,
          "cache": false,
          "processData": false,
          "mimeType": "multipart/form-data",
          "contentType": false,
          "data": form
        };
        $('#Loader').show();
        $.ajax(settings).done(function (response) {
          console.log(response);
          alert('Enquiry Successfully Submitted.');
          $('#Loader').hide();
          naviagte('/dashboard');

        });
      } else {
        alert('PCB Name & Bill of Material File is Requird');
      }
    } else {
      naviagte('/login');
    }

  };



  // const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setSelectedFile(droppedFile);
    // alert(selectedFile.name);
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    // alert('change');
    setSelectedFile(selectedFile);

  };


  return (
    <div id="servicemain">
      <div className="container-fluid py-5 service">
        <div className="row">
          <ServiceHeader />
          <form method="post" enctype="multipart/form-data">
            <div className="row mt-4">
              <div className="col-8 m-auto">
                <div className="layout_tabs_main mb-4">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCB Name: <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="pcb_name1"
                          // placeholder="PCB Name"
                          // aria-describedby="emailHelp"
                          // value={name}
                          required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-3 "
                      onDrop={handleFileDrop}
                      onDragOver={(event) => event.preventDefault()}
                    >
                      <div className="layout_tabs_main_chile">
                        <div className="" >
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Bill of Material: <sup>*</sup>
                          </label>
                          <div id="wrapper" className="dropify-wrapper">
                            <input
                              type="file"
                              name="f-img"
                              className="form-control"
                              accept=".xls,.xlsx"
                              id="fileInput"
                              ref={fileInputRef}
                              // onChange={handleImageChange}
                              onChange={MaterialsChange}
                            // onChange={handleFileInputChange}
                            />

                            <div className="dropify-message">
                              <div className="dropify-message-1">
                                <svg
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                </svg>
                                <p>Drag and drop a file here or click  <b style={{color:'#33b751'}}>File format: .xls </b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {Materials && (
                        <div >
                          <p className="m-0">Selected File: <b> {Materials.name} </b></p>
                          <button onClick={handleDeleteFile} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>

                          {/* <p className="m-0">File Size:<b>  {Materials.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {Materials.type} </b></p> */}
                        </div>
                      )}
                    </div>
                    <p style={{ "background": "#d8d8e1", "border-radius": "10px"  ,"padding": "20px"}}><i class="fa fa-bullhorn" aria-hidden="true" style={{"font-size": "20px","color":" rgb(51, 183, 81)"}}></i> {Note}</p>

                    {/* <div
                      onDrop={handleFileDrop}
                      // onDragOver={(event) => event.preventDefault()}
                      style={{
                        border: '2px dashed #ccc',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <p>Drag and drop a file here, or click to select a file.</p>
                      <input
                        type="file"
                        onChange={handleFileInputChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                      />
                      <button type="button" onClick={() => this.fileInput.click()}>
                        Select File
                      </button>
                      {selectedFile && <p>Selected File: {selectedFile.name}</p>}
                    </div> */}

                    <div
                      className="row " style={{ flexWrap: "wrap-reverse", display: "flex", justifyContent: "space-evenly", }}>
                      <div className="col-lg-12 text-center">
                        {token != null ?
                          // <a href="#" > Place inquiry</a>
                          <button className="button button--promo ms-auto" onClick={handlesubmit} >
                            Submit Your Enquiry
                          </button>
                          :
                          <a href="/login" className="button button--promo"> Please Login to place an Inquiry</a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Componentsorcing;


// import React, { useState } from "react";
// import ServiceHeader from "./ServiceHeader";

// const Componentsorcing = () => {
//   const [pcbname, setPcbname] = useState("");

//   const handle = async (e) => {
//     e.preventDefault();

//     try {
//       const formData = new FormData();
//       formData.append("pcbname", pcbname);
//       formData.append("fileInput", e.target.fileInput.files[0]);

//       const response = await fetch(
//         "https://admin.diaclabs.com/enquiry_store_component_sourcing",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       const data = await response.json();
//       console.log(data);
//       alert("Saved successfully.");
//       // You can perform additional actions with the response data here
//     } catch (err) {
//       console.error(err.message);
//     }
//   };

//   return (
//     <div id="servicemain">
//       <div className="container-fluid py-5 service">
//         <div className="row">
//           <ServiceHeader />
//           <form method="post" encType="multipart/form-data" onSubmit={handle}>
//             <div className="row mt-4">
//               <div className="col-12">
//                 <div className="layout_tabs_main mb-4">
//                   <div className="row">
//                     <div className="col mb-3">
//                       <div className="layout_tabs_main_chile">
//                         <div className="mb-3">
//                           <label
//                             htmlFor="exampleInputEmail1"
//                             className="form-label"
//                           >
//                             PCB Name: <sup>*</sup>
//                           </label>
//                           <input
//                             type="text"
//                             className="Layer_custum_css"
//                             id="pcb_name1"
//                             value={pcbname}
//                             onChange={(e) => setPcbname(e.target.value)}
//                           />
//                         </div>
//                       </div>
//                     </div>

//                     <div className="col mb-3 ">
//                       <div className="layout_tabs_main_chile">
//                         <div className="">
//                           <label
//                             htmlFor="exampleInputEmail1"
//                             className="form-label"
//                           >
//                             Bill of Material: <sup>*</sup>
//                           </label>
//                           <div id="wrapper" className="dropify-wrapper">
//                             <input
//                               type="file"
//                               name="fileInput"
//                               className="form-control"
//                               id="fileInput"
//                             />
//                             <div className="dropify-message">
//                               <div className="dropify-message-1">
//                                 <svg
//                                   width="18px"
//                                   height="18px"
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   viewBox="0 0 24 24"
//                                 >
//                                   <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
//                                 </svg>
//                                 <p>Drag and drop a file here or click</p>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div
//                       className="row "
//                       style={{
//                         flexWrap: "wrap-reverse",
//                         display: "flex",
//                         justifyContent: "space-evenly",
//                       }}
//                     >
//                       <div className="col-lg-4 mb-4 p-2 "></div>
//                       <div
//                         className="col-lg-6 offset-lg-2 "
//                         style={{
//                           color: "rgb(169, 68, 66)",
//                           backgroundColor: "rgb(235, 204, 209)",
//                           height: "3rem",
//                           borderRadius: "1rem",
//                           alignItems: "center",
//                           display: "flex",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <button type="submit">Submit</button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Componentsorcing;
