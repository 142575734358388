import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';
import Sidebar from '../dashboardsidebar';
import './css.css';
import $ from 'jquery';
import EasebuzzCheckoutButton from './EasebuzzCheckout_enquiry'; // Adjust the path accordingly

const APP_URL = "https://www.admin.diaclabs.com/";
const APP_URL2 = "https://www.admin.diaclabs.com/";

function Invoice() {
    const navigate = useNavigate()

    const [profile, setProfile] = useState([])
    const [OrderData, setOrderData] = useState([])
    const [StencilCartData, setStencilCartData] = useState([])
    const [AssemblyCatData, setAssemblyCatData] = useState([])
    const [LayoutCartData, setLayoutCartData] = useState([])
    const [Order_exception, setOrder_exception] = useState([])
    const [ExceptionMsg, setExceptionMsg] = useState([])
    const [enquiryType, setenquiryType] = useState([])

    const token = localStorage.getItem("token")
    useEffect(() => {
        const token = localStorage.getItem("token")
        // console.log(token, "token for cart")
        // const url = APP_URL + 'userProfile'

        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get("id")
        const enquiry_type = queryParams.get("type")
        // console.log('order_id ::'+order_id);
        setenquiryType(enquiry_type);
        var myHeaders = new Headers();
        myHeaders.append("authorization", token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(APP_URL + "enquiry_view_api/" + id + "/" + enquiry_type, requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
                console.log('enquiry_view_api:::::::'.orderResult);
                setOrder_exception(orderResult.enquiry_track);
                setOrderData(orderResult.result[0]);
                // setLayoutCartData(JSON.parse(orderResult.OrderData[0].LayoutCartData));
                // setStencilCartData(JSON.parse(orderResult.OrderData[0].StencilCartData));
                // setAssemblyCatData(JSON.parse(orderResult.OrderData[0].AssemblyCatData));
            })
            .catch((error) => console.log("error", error));
    }, [])
    console.log("enquiry_view_api :: " + JSON.stringify(OrderData));
    // console.log(StencilCartData);
    // console.log("AssemblyCatData :: "+AssemblyCatData);


    function Handler(event) {
        event.preventDefault();
        const queryParams = new URLSearchParams(window.location.search)
        const enquiry_type = queryParams.get("type")

        const token1 = localStorage.getItem("token");
        var attachment = document.getElementById("attachment"); // Replace "fileInput" with the actual ID of your file input element

        if (token1 != null) {
            var myHeaders = new Headers();
            myHeaders.append("authorization", token1);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new FormData();
            urlencoded.append("title", $("#title").val());
            urlencoded.append("message", $("#message").val());
            urlencoded.append("enquiry_id", OrderData.id);
            urlencoded.append("attechment", attachment.files[0]);
            urlencoded.append("enquiry_type", enquiry_type);

            var settings = {
                "url": APP_URL2 + "enquiry_trackSave_api",
                "method": "POST",
                // "timeout": 0,
                "timeout": 15000,
                "cache": false,
                "processData": false,
                "mimeType": "multipart/form-data",
                "contentType": false,
                "data": urlencoded,
                "headers": {
                    "authorization": token1, // Replace "Bearer" with your token type if needed
                }
            };

            $.ajax(settings).done(function (response) {
                console.log(response);
                alert('Enquiry Massage Send Successfully.');
                window.location = '';
                // naviagte('/dashboard');
                // navigate("/cart");
            });
        }
    }
    function OrderNow(type) {
        // alert(type);
        const token1 = localStorage.getItem("token");
        var myHeaders = new Headers();
        myHeaders.append("authorization", token1);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new FormData();
        urlencoded.append("type", type);
        urlencoded.append("id", OrderData.id);

        var settings = {
            "url": APP_URL2 + "enquiry_order_store",
            "method": "POST",
            // "timeout": 0,
            "timeout": 15000,
            "cache": false,
            "processData": false,
            "mimeType": "multipart/form-data",
            "contentType": false,
            "data": urlencoded,
            "headers": {
                "authorization": token1, // Replace "Bearer" with your token type if needed
            }
        };
        $.ajax(settings).done(function (response) {
            console.log(response);
            alert('Enquiry to Order Created Successfully.');
            // naviagte('/dashboard');
            // navigate("/cart");
            window.location = ''
        }).fail(function (xhr, status, error) {
            console.error(xhr, status, error);
            alert('An error occurred during the request. Please try again.');
        });
    }

    return (
        <>
            <div className="user-profile">

                <div className="container py-4 d-flex align-items-start" style={{ "flex-wrap": "wrap" }}>
                    {/* <Sidebar /> */}

                    {/* <iframe title="W3Schools Free Online Web Tutorials"> */}
                    <main className="main row" style={{ "width": "100%", "margin": '30px 0' }}>

                        <div className="col-md-4" style={{ "border-right": '1px solid' }}>
                            <h3>Enquiry Message</h3>
                            <button type="button" class="badge bg-primary" data-bs-toggle="modal"
                                data-bs-target="#order_exception">
                                Send Message
                            </button>
                            <hr></hr>
                            {Order_exception.map((exc, index3) => {
                                const utcTime = new Date(exc.created_at);
                                const istTime = new Date(utcTime.getTime());
                                // const istTime = new Date(utcTime.getTime() + 5.5 * 60 * 60 * 1000);
                                // const options = { timeZone: 'Asia/Kolkata',date:'2-digit', hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit' };
                                const options = {
                                    timeZone: 'Asia/Kolkata',
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: true
                                };
                                const istTimeString = istTime.toLocaleString('en-IN', options);

                                return (
                                    <div className={`p-3 ${exc.writer === 'Admin' ? 'text-end' : ''}`} key={exc.id}>
                                        <b>Sender: </b> {exc.writer} /  <b> {istTimeString} </b> <br />
                                        <span>Title: </span>
                                        {exc.title} <br />
                                        <span>Attachment: <a href={APP_URL + `/image/${exc.attechment}`}>Click to View</a></span>
                                        <br />
                                        <span>Message: <span>{exc.message}</span></span>
                                    </div>
                                )
                            })}
                        </div>
                        {/* enquiryType */}
                        {enquiryType == 'fabrication' ?
                            <>
                                {/* style="justify-content: space-between;align-items: flex-start;"
style="justify-content: space-between;align-items: flex-start;" */}
                                <div className="col-md-8 text-center">
                                    <h1 class="clearfix">Fabrication Enquiry Details  <small> {OrderData.order_id} </small></h1>
                                    <small><span>DATE : </span> <b> {OrderData.created_at} </b></small> <br></br>
                                    Enquiry Id : <span style={{ color: 'red' }}> EF_{OrderData.id} </span> <br></br>
                                    {/* Status : <span style={{ color: 'red' }}>{OrderData.status}</span> */}
                                    <hr></hr>
                                    <div class="d-flex" style={{ "justify-content": "space-between", "align-items": "flex-start" }} >
                                        <h3>Quotation Details</h3>
                                        {OrderData.status === 'Ordered' ? (
                                            <div style={{ "color": 'green' }}>
                                                Enquiry Ordered
                                            </div>
                                        ) : (
                                            OrderData.status === 'Quotation-Shared' ? (
                                                // <button type="button" className="badge bg-info p-3" onClick={() => OrderNow('PCB_Fabrication')}>
                                                //     Order Now
                                                // </button>
                                            <EasebuzzCheckoutButton/>

                                            ) : (
                                                <></> // Empty fragment for the false condition when status is neither 'Ordered' nor 'Quotation-Shared'
                                            )
                                        )}
                                    </div>
                                    <div class="col-md-11 d-flex" style={{ "justify-content": "space-between", "align-items": "flex-start" }}>
                                        <p> Status : <b class="text-info"> {OrderData.status}</b></p>
                                        <p> Amount : <b class="text-info"> {OrderData.amount}</b></p>
                                        <p> Lead Time : <b class="text-info"> {OrderData.lead_time}</b></p>
                                        <p> Message : <b class="text-info"> {OrderData.message}</b></p>
                                    </div>
                                    <hr></hr>
                                    <h4>PCB Name : <b>{OrderData.pcb_name}</b></h4>
                                    {/* {JSON.stringify(OrderData)} */}
                                    <div class="col-md-11 m-auto row" >
                                        <div class="col-md-6 p-3">
                                            Enquiry No : <b>
                                                EF_{OrderData.id}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            PCB Name : <b>
                                                {OrderData.pcb_name}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Layers : <b>
                                                {OrderData.layers}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            PCB Thickness : <b>
                                                {OrderData.pcb_thickness}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Top Solder Mask : <b>
                                                {OrderData.top_solder_mask}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Top Legend : <b>
                                                {OrderData.top_legend}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            TG : <b>
                                                {OrderData.tg}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Carbon Contacts : <b>
                                                {OrderData.carbon_contacts}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Blind Buried Via : <b>
                                                {OrderData.blind_buried_Via}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Surface Finish : <b>
                                                {OrderData.surface_finish}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Finish Cu Thickness Micron : <b>
                                                {OrderData.finish_cu_thickness_micron}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Bottom Solder Mask : <b>
                                                {OrderData.bottom_solder_mask}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Bottom Legend : <b>
                                                {OrderData.bottom_legend}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Hard Gold : <b>
                                                {OrderData.Hard_Gold}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Electrical Testing : <b>
                                                {OrderData.Electrical_Testing}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Serialization for PCBs : <b>
                                                {OrderData.Serialization_for_PCBs}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Special Buildup : <b>
                                                {OrderData.Special_buildup}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            UL Logo : <b>
                                                {OrderData.UL_Logo}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Impedance Control : <b>
                                                {OrderData.Impedance_Control}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Round Edge Platting : <b>
                                                {OrderData.Round_Edge_Platting}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Gold Area : <b>
                                                {OrderData.Gold_Area}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            panel Required : <b>
                                                {OrderData.panel_required}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Panel Dimensions : <b>
                                                {OrderData.panel_dimensions}
                                            </b>
                                        </div>
                                        <div class="col-md-6 p-3">
                                            Panel Qty : <b>
                                                {OrderData.panel_qty}
                                            </b>
                                        </div>
                                        {/* <div class="col-md-6 p-3">
                                            Enquiry At : <b>
                                               {OrderData.created_at} 
                                            </b>
                                        </div> */}
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-md-8 text-center">
                                    <h1 class="clearfix">Component Sourcing Enquiry Details  <small> {OrderData.order_id} </small></h1>
                                    <small><span>DATE : </span> <b> {OrderData.created_at} </b></small> <br></br>
                                    Enquiry Id : <span style={{ color: 'red' }}> EC_{OrderData.id} </span> <br></br>
                                    Status : <span style={{ color: 'red' }}>{OrderData.status}</span>
                                    <hr></hr>
                                    <div class="d-flex" style={{ "justify-content": "space-between", "align-items": "flex-start" }} >
                                        <h3>Quotation Details</h3>
                                        {OrderData.status === 'Ordered' ? (
                                            <div style={{ "color": 'green' }}>
                                                Enquiry Ordered
                                            </div>
                                        ) : (
                                            OrderData.status === 'Quotation-Shared' ? (
                                                // <button type="button" className="badge bg-info p-3" onClick={() => OrderNow('Component_Sourcing')}>
                                                //     Order Now
                                                // </button>
                                                 <EasebuzzCheckoutButton/>
                                            ) : (
                                                <></> // Empty fragment for the false condition when status is neither 'Ordered' nor 'Quotation-Shared'
                                            )
                                        )}
                                    </div>
                                    <div class="col-md-11 d-flex" style={{ "justify-content": "space-between", "align-items": "flex-start" }}>
                                        <p> Status : <b class="text-info"> {OrderData.status}</b></p>
                                        <p> Amount : <b class="text-info"> {OrderData.amount}</b></p>
                                        <p> Lead Time : <b class="text-info"> {OrderData.lead_time}</b></p>
                                        <p> Message : <b class="text-info"> {OrderData.message}</b></p>
                                    </div>
                                    <hr></hr>
                                    <h4>PCB Name : <b>{OrderData.pcb_name}</b></h4>
                                    <h4>Bill of Material : <a href={APP_URL + 'image/' + OrderData.bill_of_material} target="_blank" className="badge bg-primary ml-2">View</a></h4>
                                </div>
                            </>
                        }
                    </main>
                    {/* <footer>
                        Invoice was created on a computer and is valid without the signature and seal.
                    </footer> */}
                    {/* </iframe> */}

                    {/* enquiry/order/store */}
                </div>
            </div>
            <div className="modal fade" id="order_exception" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Send Message</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form action="#" method="post">
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Title</label>
                                    <input type="text" className="form-control" name="title" id="title" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Message</label>
                                    <textarea className="form-control" name="message" id="message" cols="30" rows="4"></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Attachment</label>
                                    <input type="file" className="form-control" name="attachment" id="attachment" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="badge bg-warning" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="badge bg-success" onClick={Handler}>Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Invoice;