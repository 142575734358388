import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebar from './dashboardsidebar';
const APP_URL = "https://www.admin.diaclabs.com/";

function UserProfile() {
  const [mess, setMess] = useState('')
  const [registeras, setCompany] = useState('')
  const [company_name, setcompany_name] = useState('')
  const [url, seturl] = useState('')
  const [password, setPass] = useState('')
  const [rePass, setRepass] = useState('')
  const [mobileno, setMblNo] = useState('')
  const [gst, setGstNo] = useState('')
  const [alternateno, setalternateno] = useState('')
  const [industries, setindustries] = useState('')
  const [companytype, setCompnyType] = useState('')
  const [information, setSourOfInfo] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [pincode, setPincode] = useState('')
  const [city, setCity] = useState('')
  const [districk, setdistrick] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const navigate = useNavigate()

  const [profile, setProfile] = useState([])
  const token = localStorage.getItem("token")
  useEffect(() => {
    const token = localStorage.getItem("token")
    console.log(token, "token for cart")
    const url = APP_URL + 'userProfile'
    console.log(url);
    console.log(APP_URL);


    fetch(APP_URL + 'userProfile', {
      headers: {
        Authorization: `${token}`
      }
    }

    ).then((res) => res.json()).then((data) => {

      // console.log(data,"value of cart")
      console.log(data, 'data')
      setProfile(data.message[0])
      const lvlup = data.message.filter((item) => {
        localStorage.setItem('name', item.firstname)
        console.log(item.firstname);
      })
    })
  }, [])
  

  function userProfileEditPost() {
    // event.preventDefault();
    // alert(profile.address1);
    var token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("address1", profile.address1);
    urlencoded.append("address2", profile.address2);
    urlencoded.append("pincode", profile.pincode);
    urlencoded.append("districk", profile.districk);
    urlencoded.append("city", profile.city);
    urlencoded.append("state", profile.state);
    urlencoded.append("country", profile.country);
    
    urlencoded.append("authorization", token);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(APP_URL+"userAddressEditPost", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

      navigate("/userProfile");
  }

  function Edit(id) {
    navigate("/Edit/" + id)


  }
  function Editp(id) {
    navigate("/Editpage/" + id)


  }
  return (
    <>

      <div className="user-profile">
        <div className="container py-4 d-flex align-items-start">
        <Sidebar />
          
          
          {/* <div className="row">
            <div className="col-2">
              <div className="overview_box py-2">
                <h3>Overview</h3>
              </div>
              <hr className="border_hr" />
              <div
                className="nav flex-column nav-pills overview_box me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical">
                <h3 className="mb-0">ORDERS</h3>
                <button
                  className="nav-link"
                  id="My_Orders"
                  data-bs-toggle="pill"
                  data-bs-target="#My_Orders-tab"
                  type="button"
                  role="tab"
                  aria-controls="My_Orders"
                  aria-selected="true"
                >
                  My Orders
                </button>
                <button
                  className="nav-link"
                  id="shipped-Orders-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#shipped-Orders"
                  type="button"
                  role="tab"
                  aria-controls="shipped-Orders"
                  aria-selected="false"
                >
                  shipped Orders
                </button>
                <button
                  className="nav-link"
                  id="Exceptions-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#Exceptions"
                  type="button"
                  role="tab"
                  aria-controls="Exceptions"
                  aria-selected="false"
                >
                  Exceptions
                </button>
                <button
                  className="nav-link"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Excalation
                </button>
                <hr className="border_hr " />
                <h3 className="mb-0">ACCOUNT</h3>
                <button
                  className="nav-link active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  User Profile
                </button>
                <button
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  Address Management
                </button>
                <button
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  Email Management
                </button>
                <hr className="border_hr " />
                <h3 className="mb-0">TRACKING</h3>
                <button
                  className="nav-link"
                  id="Track-Inquiry-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#Track-Inquiry"
                  type="button"
                  role="tab"
                  aria-controls="Track-Inquiry"
                  aria-selected="true"
                >
                  Track Inquiry
                </button>
                <button
                  className="nav-link"
                  id="Track-Quotation-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#Track-Quotation"
                  type="button"
                  role="tab"
                  aria-controls="Track-Quotation"
                  aria-selected="false"
                >
                  Track Quotation
                </button>
                <button
                  className="nav-link"
                  id="Email-Management_sec-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#Email-Management_sec"
                  type="button"
                  role="tab"
                  aria-controls="Email-Management_sec"
                  aria-selected="false"
                >
                  Email Management
                </button>
              </div>
              
            </div>
          </div> */}

          <div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                  Address Line 1: <sup>*</sup>
                </label>
                <i className="fa fa-map-signs" />
                <input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Enter Address Line 1"
                  className="form_group_select_box"
                  // onChange={(e) => setAddress1(e.target.value)}
                  onChange={(e) => setProfile({ ...profile, address1: e.target.value })}
                  value={profile.address1}
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">Address Line 2:</label>
                <i className="fa fa-map-signs" />
                <input
                  type="text"
                  name="add2"
                  placeholder="Enter Address Line 2"
                  className="form_group_select_box"
                  onChange={(e) => setProfile({ ...profile, address2: e.target.value })}
                  value={profile.address2}
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                  Pincode: <sup>*</sup>
                </label>
                <i className="fa fa-map-pin" />
                <input
                  type="number"
                  name="Pincode"
                  placeholder="Enter Pincode"
                  className="form_group_select_box"
                  onChange={(e) => setProfile({ ...profile, pincode: e.target.value })}
                  value={pincode}
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                  City: <sup>*</sup>
                </label>
                <i className="fa fa-map-marker" />
                <input
                  type="text"
                  name="New_city"
                  placeholder="Enter City"
                  className="form_group_select_box"
                  onChange={(e) => setProfile({ ...profile, city: e.target.value })}

                  value={profile.city}
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                District: <sup>*</sup>
                </label>
                <i className="fa fa-map-marker" />
                <input
                  type="text"
                  name="New_districk"
                  placeholder="Enter districk"
                  className="form_group_select_box"
                  onChange={(e) => setProfile({ ...profile, districk: e.target.value })}

                  value={profile.districk}

                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                  State: <sup>*</sup>
                </label>
                <i className="fa fa-map i-top" />
                <select name={909} className="form_group_select_box"  
                onChange={(e) => setProfile({ ...profile, state: e.target.value })}
                  value={profile.state}
                >
                  <option value="">Select state</option>
                  <option value="AN">Andaman and Nicobar Islands</option>
                  <option value="AP">Andhra Pradesh</option>
                  <option value="AR">Arunachal Pradesh</option>
                  <option value="AS">Assam</option>
                  <option value="BR">Bihar</option>
                  <option value="CH">Chandigarh</option>
                  <option value="CT">Chhattisgarh</option>
                  <option value="DN">Dadra and Nagar Haveli</option>
                  <option value="DD">Daman and Diu</option>
                  <option value="DL">Delhi</option>
                  <option value="GA">Goa</option>
                  <option value="GJ">Gujarat</option>
                  <option value="HR">Haryana</option>
                  <option value="HP">Himachal Pradesh</option>
                  <option value="JK">Jammu and Kashmir</option>
                  <option value="JH">Jharkhand</option>
                  <option value="KA">Karnataka</option>
                  <option value="KL">Kerala</option>
                  <option value="LA">Ladakh</option>
                  <option value="LD">Lakshadweep</option>
                  <option value="MP">Madhya Pradesh</option>
                  <option value="MH">Maharashtra</option>
                  <option value="MN">Manipur</option>
                  <option value="ML">Meghalaya</option>
                  <option value="MZ">Mizoram</option>
                  <option value="NL">Nagaland</option>
                  <option value="OR">Odisha</option>
                  <option value="PY">Puducherry</option>
                  <option value="PB">Punjab</option>
                  <option value="RJ">Rajasthan</option>
                  <option value="SK">Sikkim</option>
                  <option value="TN">Tamil Nadu</option>
                  <option value="TG">Telangana</option>
                  <option value="TR">Tripura</option>
                  <option value="UP">Uttar Pradesh</option>
                  <option value="UT">Uttarakhand</option>
                  <option value="WB">West Bengal</option>
                </select>
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <div className="form_group_1">
                <label className="form__label">
                  Country: <sup>*</sup>
                </label>
                <i className="fa fa-globe i-top" />
                <input
                  type="text"
                  name="New_Country"
                  id="country"
                  placeholder="India"
                  className="form_group_select_box"
                  onChange={(e) => setProfile({ ...profile, country: e.target.value })}

                  value={profile.country}
                />
              </div>


            </div>
            <div className="col-lg-4 pt-5">
              <button className="btn btn-dark w-100 pt-3" onClick={userProfileEditPost}>Save</button>

            </div>

          </div>


        </div>



      </div>
    </>
  );
}

export default UserProfile;