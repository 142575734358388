import bord from "../pcb-board.svg";
import layer from "../layer.svg";
import flex from "../flexpcb.png";
import circtbord from "../circuit-board.svg";
import chip from "../chip.svg";
import { LoginContext } from "../LoginContext";
import { Component, useContext } from "react";
// Import Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Import Bootstrap JavaScript (if needed)
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useState, useEffect } from "react";
const APP_URL = "https://www.admin.diaclabs.com/";
function Service() {
  const [stencilName, setStencilName] = useState("");
  const [layerCompo, setLayerCompo] = useState("");
  const [pcbComp, setPcbcomp] = useState("");

  const [upload, setUpload] = useState([]);
  const [pcbName, setPcbname] = useState("");
  console.log(pcbName);
  const [pcbLayer, setPcblayer] = useState("");
  // const [pcbComp, setPcbcomp] = useState("");
  const [pcbTime, setPcbtime] = useState("");
  const [workingdays, setWorkingDays] = useState([]);
  const [price, setPrice] = useState("");

  const [listOfLayoutPcb, setListOfLayoutPcb] = useState([]);

  const [selectedLayer, setSelectedLyer] = useState({ components: [] });
  console.log(selectedLayer);
  const [selectedComponentsAll, setSelectedComponentsAll] = useState([]);
  const [selectedComponents, setSelectedComponents] = useState({
    price: 0,
    days: 0,
  });

  const [listOfStencil, setListOfStencil] = useState([]);
  const [selectedStencilVeriAll, setSelectedStencilVeriAll] = useState([]);
  const [selectedStencilVeri, setSelectedStencilVeri] = useState({
    price: 0,
    days: 0,
  });

  const pcbData = { pcbLayer, pcbComp, pcbTime }
     console.log(pcbLayer)
     console.log(price)

  if (upload.size > 5e6) {
    alert("file size must be only 5MB");
  }

  const [layerCom, setLayerCom] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(layerCom, "layerrrr")

  const [layer, setlayer] = useState([]);
  // console.log(layer,"this is real la yer ")
  const [layertitle, setTitle] = useState("");
  const [layerPrice, setLayerPrice] = useState(0);


  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);

    setIsShown(true);
  };
  useEffect(() => {

      const apiUrl = APP_URL+'getAllPcbLayoutLayer'; // Replace this with your API endpoint URL
    console.log(apiUrl);


      // Fetch data from the API using the fetch() function
      fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setlayer(data.message);
              // console.log(data.message,"componeent data")
              setLoading(false); // Set loading state to false
              let levelUp = data.message.filter((item) => {
                  setLayerCom(item.component)
                  // console.log(item.component,"item data")
                   setLayerCom(item.component)

              })

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              setLoading(false); // Set loading state to false even in case of an error
          });

      }, [])

  // console.log(layerCom, 'layer component in layer')







 

  





      // Fetch data from the API using the fetch() function
      const [components, setComponents] = useState([]);
      console.log(components);


    //   const [componData, setComponentData] = useState([]);
    
      useEffect(() => {
      const apiUrl = APP_URL+'getAllPcbLayoutComponent'; // Replace this with your API endpoint URL
      console.log(apiUrl);

        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            setComponents(data.message);
    
            setLoading(false); // Set loading state to false
          })
          .catch((error) => {
            // console.error("Error fetching data:", error);
            setLoading(false); // Set loading state to false even in case of an error
          });
      });
  
  const [days, setDays] = useState([]);
  const [extime, setExtim] = useState([]);

  useEffect(() => {

      const apiUrl = APP_URL+'getAllPcbLayoutDay'; // Replace this with your API endpoint URL
    console.log(apiUrl);


      // Fetch data from the API using the fetch() function
      fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setDays(data.message);
              // console.log(days)
              //   console.log(data.message)
              setLoading(false); // Set loading state to false

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              setLoading(false); // Set loading state to false even in case of an error
          });

  }, [])

  const [comptitle, setCompTitle] = useState("");
  const [compPrice, setCompPrice] = useState("");

  function handleComp(param) {
      const compData = components.filter((item) => item.id == param)
      console.log(compData[0].price, "comp cheking")
      setCompTitle(compData[0].title)
      setCompPrice(parseInt(compData[0].price))
  }

  function handleDays(param) {
    const daysData = days.filter((item) => item.id === param);
    // console.log(daysData[0].title, "checking")
    setWorkingDays(daysData[0].title);
    setPrice(parseInt(daysData[0].price));
    // setExtim(parseInt(daysData[0].price))
    console.log(setWorkingDays);

  }


  function handleLayer(param) {
    const layerData = layer.filter((item) => item.id == param);
    // console.log(layerData[0].price, "layer cheking")
    setTitle(layerData[0].title);
    setLayerPrice(parseInt(layerData[0].price));
    setLayerCompo(parseInt(layerData[0].component));
  }

  const [stencilSize, setStencilSize] = useState([]);
  const [stencilSizePrice, setStencilSizePrice] = useState(0);

  useEffect(() => {

      const apiUrl = APP_URL+'getAllStencilSize'; // Replace this with your API endpoint URL
    console.log(apiUrl);

      

      // Fetch data from the API using the fetch() function
      fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setStencilSize(data.message);
              //   console.log(data.message)
              setLoading(false); // Set loading state to false

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              setLoading(false); // Set loading state to false even in case of an error
          });

  }, [])

  const [stencilDay, setStencilDay] = useState([]);

  useEffect(() => {

      const apiUrl = APP_URL+'getAllStencilDay'; // Replace this with your API endpoint URL
    console.log(apiUrl);


      // Fetch data from the API using the fetch() function
      fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setStencilDay(data.message);
              // console.log("data.message",data.message)
              setLoading(false); // Set loading state to false

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              setLoading(false); // Set loading state to false even in case of an error
          });

  }, [])

  const [stencilVariant, setStencilVar] = useState([]);
  const [variantPrice, setVeriantPrice] = useState(0);

  useEffect(() => {

      const apiUrl = APP_URL+'getAllStencilVariants'; // Replace this with your API endpoint URL
    console.log(apiUrl);


      // Fetch data from the API using the fetch() function
      fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
              setStencilVar(data.message);
              // console.log(data.message)
              setLoading(false); // Set loading state to false

          })
          .catch((error) => {
              console.error('Error fetching data:', error);
              setLoading(false); // Set loading state to false even in case of an error
          });

  }, []);

  const [stencilWorkingDay, setStencilWorkingDay] = useState([]);

  function handlestencilDay(param) {
    const daysData = stencilDay.filter((item) => item.id === param);
    console.log(daysData[0].title, "checking")
    setStencilWorkingDay(daysData[0].title);
  }

  let date = new Date();
  let tentiveData =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  // console.log(tentiveData)

  // const { cart, setCart } = useContext(LoginContext)
  const [cart, setCart] = useState([]);

  const cartValue = { layertitle, comptitle, workingdays };
  const addcart = (e) => {
    e.preventDefault();
    let _cart = { ...cart };
    if (!_cart.items) {
      _cart.items = cartValue;
    }

    if(_cart.items){ //id does exist in the _cart.items this will increment in numbers of id
        _cart.items+=1
    }else{//it means that id or item add first time
        if(!_cart.totalItems){//agr total item nhi hai
            _cart.totalItems=1
        }else{
                _cart.totalItems+=1
            }
            _cart.items=1
        }
    setCart(_cart);
    localStorage.setItem("cart", JSON.stringify(_cart));
    // console.log(_cart)

    // console.log("werhysdjika", pcbLayer[3])

    localStorage.setItem('pcbLayer', (pcbLayer))
    localStorage.setItem('pcbPrice', (pcbLayer))
  };

  useEffect(() => {
    getLayoutPriceHandler();
    getStencilPriceHandler();
  }, []);

  const getLayoutPriceHandler = (e) => {
    // e.preventDefault();
    const apiUrl = APP_URL+"pricing"; // Replace this with your API endpoint URL
    console.log(apiUrl);

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // setStencilVar(data.message);
        console.log("===========> ", data.data);
        setListOfLayoutPcb(data.data);
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  };

  const selectComponentsHandler = (layer) => {
    const getSelectLayer = listOfLayoutPcb.filter((i) => i.layer == layer);

    if (getSelectLayer.length != 0) {
      setSelectedComponentsAll(getSelectLayer[0].components);
    }
  };

  const getPriceAndDaysHandler = (item) => {
    const getAr = selectedComponentsAll.filter((i) => `${item.component_start}-${item.component_end}` == item)
    if (getAr.length != 0) {
        setSelectedComponents(getAr[0]);
    }
    if (item == "Select Component") {
      setSelectedComponents({ price: 0, days: 0 });
    } else {
      setSelectedComponents(selectedComponentsAll[item]);
    }
    //

    // console.log("================> ", type(item));
  };

  const getStencilPriceHandler = (e) => {
    // e.preventDefault();
    const apiUrl = "http://localhost:3030/stencil/pricing"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // setStencilVar(data.message);
        console.log("===========> ", data.data);
        setListOfStencil(data.data);
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  };

  const selectStencilVeriHandler = (stencil_size) => {
    const getSelectLayer = listOfStencil.filter(
      (i) => i.stencil_size == stencil_size
    );

    if (getSelectLayer.length != 0) {
      setSelectedStencilVeriAll(getSelectLayer[0].variants);
    }
  };

  const getStencilPriceAndDaysHandler = (item) => {
    // const getAr = selectedComponentsAll.filter((i) => `${item.component_start}-${item.component_end}` == item)
    // if (getAr.length != 0) {
    //     setSelectedComponents(getAr[0]);
    // }
    if (item == "Select Variants") {
      setSelectedStencilVeri({ price: 0, days: 0 });
    } else {
      setSelectedStencilVeri(selectedStencilVeriAll[item]);
    }
    // setSelectedStencilVeri(selectedStencilVeriAll[item]);
  };

  return (
    <>
      <div id="servicemain">
        <section className="container-fluid py-5 service">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active my-1"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={bord} />
                      <span>PCB Layout</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link my-1"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={bord} />
                      <span>PCB Fabrication & PCB Assembly</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link my-1"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={flex} />
                      <span>PCB Fabrication</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link my-1"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-pcb"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-pcb"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={chip} />
                      <span>PCB Stencil</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link my-1"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-pcs"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-pcs"
                    aria-selected="false"
                  >
                    <div className="tab_oppo d-flex align-items-center">
                      <img src={circtbord} />
                      <span>Component Sourcing</span>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <form>
                <div className="row mt-4">
                  <div className="col-lg-8">
                    <div className="layout_tabs_main mb-4">
                      <div className="row">
                        <div className="col-sm-4 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                PCB Names: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="PCB Name"
                                aria-describedby="emailHelp"
                                value={pcbName}
                                onChange={(e) => setPcbname(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Layer: <sup>*</sup>
                              </label>
                              <select class="w-100 Layer_custum_css">
                                {" "}
                                {layer.map((item) => {
                                  return (
                                    <>
                                      <option value={item.id}>
                                        {item.title}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>

                             
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                No. of Components: <sup>*</sup>
                              </label>
                             <select class="w-100 Layer_custum_css">
                                {" "}
                                {components.map((item) => {
                                  return (
                                    <>
                                      <option value={item.id}>
                                        {item.title}
                                      </option>
                                    </>
                                  );
                                })}
                               
                              </select>

                             
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                             <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Lead Time: <sup>*</sup>
                              </label>
                             <select class="w-100 Layer_custum_css">
                                
                                {days.map((item,id) => {
                                  return (
                                    <>
                                      <option value={item.id}>
                                        {item.title}
                                      </option>
                                    </>
                                  );
                                })}
                               
                              </select>

                               
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Schematic: <sup>*</sup>
                              </label>
                              <div id="wrapper" className="dropify-wrapper">
                                <input
                                  type="file"
                                  // name="f-img"
                                  className="form-control"
                                  onChange={(e) => setUpload(e.target.files[0])}
                                />
                                <div className="dropify-message">
                                  <div className="dropify-message-1">
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                    </svg>
                                    <p>Drag and drop a file here or click</p>
                                  </div>
                                  {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="add_to_card">
                            <button
                              type="submit"
                              className="btn btn-primary add-to-card"
                              onClick={(e) => {}}
                            >
                              Add to Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="layout_tabs_main">
                      <div className="calculated_price_heading">
                        <h5 className="text-center w-100 text-green fontw-600 mb-0">
                          Calculated Price (₹)
                        </h5>
                      </div>
                      <div className="stencil_calculated_price calculated_price_padding">
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Lead Time:</label>
                          <label className="price-content">{workingdays}</label>
                        </div>
                        {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Components:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {compPrice}
                                                </label>
                                            </div>{" "} */}
                        {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Days:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {price}
                                                </label>
                                            </div>{" "} */}
                        {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Layer:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {layerPrice}
                                                </label>
                                            </div>{" "} */}


                                            
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Order Value:</label>
                          <label id="OrderValue" className="price-content">
                            {selectedComponents.price}
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Tax(%):</label>
                          <label id="OrderTax" className="price-content">
                            18
                          </label>
                        </div>
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Total Price:</label>
                          <label
                            id="TotalOrderPrice"
                            className="price-content total-price"
                          >
                            {(
                              (parseInt(selectedComponents.price) / 100) * 18 +
                              parseInt(selectedComponents.price)
                            ).toFixed(2)}
                          </label>
                        </div>
                        <hr className="price-hr" />{" "}
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Tentative Shipment Date:
                          </label>{" "}
                          <label id="ShipmentDate" className="price-content">
                            {new Date(
                              new Date().setDate(
                                new Date().getDate() +
                                  parseInt(selectedComponents.days)
                              )
                            )
                              .toISOString()
                              .slice(0, 10)}
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row">
                <div className="col-lg-8 mb-3">
                  <div className="layout_tabs_main">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              PCB Name: <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="Layer_custum_css"
                              id="exampleInputtext"
                              placeholder="PCB Name"
                              aria-describedby="emailHelp"
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-lg-4 mt-3 offset-2">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Panel reuired: <sup>*</sup>
                        </label>
                        <br />
                        <div className="radio-button" onClick={handleClick} >
                          <input
                            type="radio"
                            name="radio52"
                            id="radioacl"
                            defaultChecked=""
                          />
                          <label htmlFor="radioacl">No</label>
                          {
                            isShown && (
                        
                <>
                <input type="radio" name="radio52" id="radiobl" data-bs-toggle="fim" data-bs-target="#exampleModal"/>
                          <label htmlFor="radiobl">Yes</label>
                      
                </>
                          
                            )}
                        </div>
                      </div>



                      <div className="col-lg-6">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              No of layers: <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="Layer_custum_css"
                              id="exampleInputtext"
                              placeholder="PCB Layers"
                              aria-describedby="emailHelp"
                            />
                          </div>
                        </div>
                      </div>
                    
                          <>
                          <div className="col-lg-6 model" id='#exampleModal'>
                       
                       <label
                         htmlFor="exampleInputEmail1"
                         className="form-label"
                       >
                        PCB Dimensions(mm): <sup>*</sup>
                       </label>
                       <div className="Dimensions_input d-flex align-items-center">
                         <input type="text" defaultValue={5} name="f" />
                         <span>X</span>
                         <input type="text" defaultValue={6} name="ff" />
                         <span>MM</span>
                       </div>
                     </div>
                          </>
                       
                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          PCB Qty: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Panel requireds: <sup>*</sup>
                            </label>
                            <input
                          type="text"
                          // defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"

                          placeholder="pcb layers"
                        />
                          
                          </div>
                        </div>
                      </div>
                       <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          PCB Layer: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          // defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"

                          placeholder="pcb layers"
                        />
                      </div>
                     
                      <div className="col-lg-6 mb-3 dim" id='show'>
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Pcb thickness (mm): <sup>*</sup>
                            </label>
                            <select className="w-100 Layer_custum_css">
                              <option>0.4</option>
                              <option>0.5</option>
                              <option>0.3</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      
                      <div className="col-lg-6 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                            No of Throughhole components pads: <sup>*</sup>
                            </label>
                            <select className="w-100 Layer_custum_css">
                              <option>HAL</option>
                              <option>HAL(Sn-Pd)</option>
                              <option>immersion Tin</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Total PGA pades: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"

                          placeholder="Total pades"
                        />
                      </div>
                      <div className="col-lg-6 ">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                        No of SMD components Pads exluding BGA components Pads : <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"

                          placeholder="Total pades"
                        />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              No of board to be assembled: <sup>*</sup>
                            </label>
                            <select className="w-100 Layer_custum_css">
                              <option>less than 10</option>
                              <option>11-100</option>
                              <option>51-100</option>
                              <option>101-100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                     
                      <div className="col-lg-12 my-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                         total pades: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          defaultValue={10}
                          name="from"
                          oninput=""
                          className="form__field Quantity Layer_custum_css"

                          placeholder="Total pades"
                        />
                      </div>
                      
                   
               
                     
                      <div className="col-sm-4 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Gerber Data: <sup>*</sup>
                            </label>
                            <div id="wrapper" className="dropify-wrapper">
                              <input
                                type="file"
                                name="f-img"
                                className="form-control"
                              />
                              <div className="dropify-message">
                                <div className="dropify-message-1">
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                  </svg>
                                  <p>Drag and drop a file here or click</p>
                                </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Bill of Material: <sup>*</sup>
                            </label>
                            <div id="wrapper" className="dropify-wrapper">
                              <input
                                type="file"
                                name="f-img"
                                className="form-control"
                              />
                              <div className="dropify-message">
                                <div className="dropify-message-1">
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                  </svg>
                                  <p>Drag and drop a file here or click</p>
                                </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Pick &amp; Place: <sup>*</sup>
                            </label>
                            <div id="wrapper" className="dropify-wrapper">
                              <input
                                type="file"
                                name="f-img"
                                className="form-control"
                              />
                              <div className="dropify-message">
                                <div className="dropify-message-1">
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                  </svg>
                                  <p>Drag and drop a file here or click</p>
                                </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="add_to_card">
                          <button
                            type="submit"
                            className="btn btn-primary add-to-card"
                          >
                            Add to Card
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="layout_tabs_main">
                    <div className="calculated_price_heading">
                      <h5 className="text-center w-100 text-green fontw-600 mb-0">
                        Calculated Price (₹)
                      </h5>
                    </div>
                    <div className="stencil_calculated_price calculated_price_padding">
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Service</label>
                        <label className="price-content price-title">
                          PCB Fabrication
                        </label>
                        <label
                          id="lblasm"
                          className="price-content price-title div1 hide"
                        >
                          PCB Assembly
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Lead Time:</label>
                        <label id="PriceLeadTime" className="price-content">
                          7 WD
                        </label>
                        <label
                          id="PriceAssemblyLeadTime"
                          className="price-content div1 hide"
                        >
                          7 WD
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Quantity:</label>
                        <label id="CalpcbQty" className="price-content">
                          10
                        </label>
                        <label
                          id="qtyAsm"
                          className="price-content div1 hide getExtraPadding"
                        >
                          -
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Unit Price:</label>
                        <label id="FabUnitPrice" className="price-content">
                          148
                        </label>
                        <label
                          id="AssUnitPrice"
                          className="price-content div1 hide getExtraPadding"
                        >
                          -
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Order Price:</label>
                        <label
                          id="FabOrderPrice"
                          className="price-content"
                        ></label>{" "}
                        <label
                          id="AssOrderPrice"
                          className="price-content div1 hide getExtraPadding"
                        >
                          -
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Tax(%):</label>
                        <label id="FabTax" className="price-content">
                          18
                        </label>
                        <label
                          id="AssTax"
                          className="price-content div1 hide getExtraPadding"
                        >
                          -
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Total Price:</label>
                        <label
                          id="PcbTotalPrice"
                          className="total-price price-content"
                        >
                          1746
                        </label>
                        <label
                          id="AssTotalPrice"
                          className="total-price price-content div1 hide getExtraPadding"
                        >
                          -
                        </label>
                      </div>{" "}
                      <hr className="price-hr" />
                      <div className="bottom-10"></div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Maximum Sq Meter:</label>{" "}
                        <label className="price-content">8</label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Your Sq Meter:</label>
                        <label id="YourSqmeter" className="price-content">
                          0.00
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Setup Charges:</label>
                        <label className="price-content total-price">
                          Free
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">Delivery Charges:</label>{" "}
                        <label className="price-content total-price">
                          Free
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">
                          Electrical Testing:
                        </label>
                        <label className="price-content total-price">
                          Free
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <label className="price-title">
                          Tentative Shipment Date:
                        </label>{" "}
                        <label className="price-content total-price">
                          23/08/2023
                        </label>
                      </div>
                      <div className="d-flex justify-content-end fix-validation-text">
                        <label className="text-red" style={{ fontSize: 10 }}>
                          * WD = Working Days
                        </label>
                      </div>
                    </div>
                    <hr className="price-hr" />
                    <div className="text-center bottom-10 d-flex justify-content-center align-items-center">
                      <a type="submit" className="button button--green top-20u">
                        <span>Compare prices</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <form>
                <div className="row mt-4">
                  <div className="col-lg-8">
                    <div className="layout_tabs_main mb-4">
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                PCB Name: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="PCB Name"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        

                        <div className="col-lg-4 mt-3 offset-2">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Panel reuired: <sup>*</sup>
                        </label>
                        <br />
                        <div className="radio-button" onClick={handleClick} >
                          <input
                            type="radio"
                            name="radio52"
                            id="radioacl"
                            defaultChecked=""
                          />
                          <label htmlFor="radioacl">No</label>
                          {
                            isShown && (
                        
                <>
                <input type="radio" name="radio52" id="radiobl" data-bs-toggle="fim" data-bs-target="#exampleModal"/>
                          <label htmlFor="radiobl">Yes</label>
                      
                </>
                          
                            )}
                        </div>
                        </div>
                        <div className="col-lg-6 model" id='#exampleModal'>
                       
                       <label
                         htmlFor="exampleInputEmail1"
                         className="form-label"
                       >
                        PCB Dimensions(mm): <sup>*</sup>
                       </label>
                       <div className="Dimensions_input d-flex align-items-center">
                         <input type="text" defaultValue={5} name="f" />
                         <span>X</span>
                         <input type="text" defaultValue={6} name="ff" />
                         <span>MM</span>
                       </div>
                     </div>
                        <div className="col mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Panel required: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Panel required"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                PCBs/Panel (QTY): <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="PCBs/Panel"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Top solider Mask: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Top solider Mask"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Top Legend: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Top Legend"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                TG: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" TG"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Via Fill: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Via Fill"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Carbon Contact: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="  Carbon Contact"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Hard Gold: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Hard Gold"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Blind & Buried Via: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Blind & Buried Via"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Electrical Testing: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Electrical Testing"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                               Carbon contacts: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Carbon contacts"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>  <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                               Special buildup: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Special buildup"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>    <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                carbon contacts: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="corbon contact"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>  <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                              UL Logo: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="UL Logo"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>  <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                              carbon contacts: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="corbon contacts"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>  <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                             Round Edge Platting: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Round Edge Platting"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                          Gold Area (Approx)  : <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder=" Gold Area (Approx)"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>

                        
  
                       
                        
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                FPC Thickness (mm): <sup>*</sup>
                              </label>
                              <select className="w-100 Layer_custum_css">
                                <option>0.06</option>
                                <option>0.11</option>
                                <option>0.12</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Surface Finish: <sup>*</sup>
                              </label>
                              <select className="w-100 Layer_custum_css">
                                <option>Only Coper</option>
                                <option>ENIG</option>
                                <option>Immersion Tin</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form_group mt-3">
                            <label className="form__label">PCB Qty: </label>
                            <div className="buttoll mt-1">
                              <input
                                type="text"
                                className="directory_disable Layer_custum_css"
                                disabled=""
                                name="tamp"
                                defaultValue=" High Tg (230 deg)"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                PCB Production Lead Time: <sup>*</sup>
                              </label>
                              <select className="w-100 Layer_custum_css">
                                <option>15 Working Day</option>
                                <option>20 Working Day</option>
                                <option>25 Working Day</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Gerber Data: <sup>*</sup>
                              </label>
                              <div id="wrapper" className="dropify-wrapper">
                                <input
                                  type="file"
                                  name="f-img"
                                  className="form-control"
                                />
                                <div className="dropify-message">
                                  <div className="dropify-message-1">
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                    </svg>
                                    <p>Drag and drop a file here or click</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="add_to_card">
                            <button
                              type="submit"
                              className="btn btn-primary add-to-card"
                            >
                              Add to Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="layout_tabs_main">
                      <div className="calculated_price_heading">
                        <h5 className="text-center w-100 text-green fontw-600 mb-0">
                          Calculated Price (₹)
                        </h5>
                      </div>
                      <div className="stencil_calculated_price calculated_price_padding">
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Service</label>
                          <label className="price-content price-title">
                            PCB Fabrication Flex
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Lead Time:</label>
                          <label id="PriceLeadTime" className="price-content">
                            7 WD
                          </label>
                        </div>
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Quantity:</label>
                          <label id="CalpcbQty" className="price-content">
                            10
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Unit Price:</label>
                          <label id="FabUnitPrice" className="price-content">
                            148
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Order Price:</label>
                          <label id="FabOrderPrice" className="price-content">
                            1480
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Tax(%):</label>
                          <label id="FabTax" className="price-content">
                            18
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Total Price:</label>
                          <label
                            id="PcbTotalPrice"
                            className="total-price price-content"
                          >
                            1746
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="bottom-10"></div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Maximum Sq Meter:
                          </label>{" "}
                          <label className="price-content">8</label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Your Sq Meter:</label>
                          <label id="YourSqmeter" className="price-content">
                            0.00
                          </label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Setup Charges:</label>
                          <label className="price-content total-price">
                            Free
                          </label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Delivery Charges:
                          </label>{" "}
                          <label className="price-content total-price">
                            Free
                          </label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Electrical Testing:
                          </label>
                          <label className="price-content total-price">
                            Free
                          </label>
                        </div>
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Tentative Shipment Date:
                          </label>{" "}
                          <label className="price-content total-price">
                            23/08/2023
                          </label>
                        </div>
                        <div className="d-flex justify-content-end fix-validation-text">
                          <label className="text-red" style={{ fontSize: 10 }}>
                            * WD = Working Days
                          </label>
                        </div>
                      </div>
                      <hr className="price-hr" />
                      <div className="text-center bottom-10 d-flex justify-content-center align-items-center">
                        <a
                          type="submit"
                          className="button button--green top-20u"
                        >
                          <span>Compare prices</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact-pcb"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <form>
                <div className="row mt-4">
                  <div className="col-lg-8">
                    <div className="layout_tabs_main mb-4">
                      <div className="row">
                        <div className="col-sm-12 mb-3 lg-12">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Stencil Name: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="Stencil Name"
                                aria-describedby="emailHelp"
                                value={stencilName}
                                onChange={(e) => setStencilName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Stencil Size: <sup>*</sup>
                          </label>
                          <div className="size_active ">
                            <select
                              className="w-100 Layer_custum_css"
                              onChange={(e) =>
                                selectStencilVeriHandler(e.target.value)
                              }
                            >
                              <option>Select Stencil Size</option>

                              {listOfStencil.map((item) => {
                                return (
                                  <option value={item.stencil_size}>
                                    {item.stencil_size}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Stencil Variants: <sup>*</sup>
                          </label>
                          <div className="size_active">
                            <select
                              className="w-100 Layer_custum_css"
                              onChange={(e) =>
                                getStencilPriceAndDaysHandler(e.target.value)
                              }
                            >
                              <option>Select Variants</option>
                              {selectedStencilVeriAll.map((item, index) => {
                                // console.log(item,"varieants`")
                                return (
                                  <option value={index}>{item.variants}</option>
                                );
                              })}

                              {/* <option >paste stencil</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Lead Time: <sup>*</sup>
                          </label>
                          <div className="size_active">
                            {/* <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a2510"
                                                            name="check-substitution-237"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a2510">
                                                            Framed
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a5020"
                                                            name="check-substitution-237"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a5020">
                                                            Frameless
                                                        </label>
                                                    </div> */}
                            <div className="w-100 Layer_custum_css">
                              <label className="price-title">
                                {selectedStencilVeri.days} Days
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Schematic: <sup>*</sup>
                              </label>
                              <div id="wrapper" className="dropify-wrapper">
                                <input
                                  type="file"
                                  name="f-img"
                                  className="form-control"
                                />
                                <div className="dropify-message">
                                  <div className="dropify-message-1">
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                    </svg>
                                    <p>Drag and drop a file here or click</p>
                                  </div>
                                  {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Bill of Material: <sup>*</sup>
                              </label>
                              <div id="wrapper">
                                <input
                                  type="file"
                                  name="f-img"
                                  className="form-control opcity_set"
                                />
                                <div className="dropify-message">
                                  <div className="dropify-message-1">
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                    </svg>
                                    <p>Drag and drop a file here or click</p>
                                  </div>
                                  {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Size X and Y: <sup>*</sup>
                                                </label>
                                                <div className="Dimensions_input d-flex align-items-center">
                                                    <input type="text" defaultValue={5} name="f" />
                                                    <span>X</span>
                                                    <input type="text" defaultValue={6} name="ff" />
                                                    <span>MM Y</span>
                                                    <input type="text" defaultValue={51} name="ff" />
                                                    <span>MM</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-2">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Thickness (mil): <sup>*</sup>
                                                </label>
                                                <div className="size_active ">
                                                    <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a2510"
                                                            name="check-substitution-240"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a2510">
                                                            4
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a5020"
                                                            name="check-substitution-240"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a5020">
                                                            5
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a7520"
                                                            name="check-substitution-240"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a7520">
                                                            6
                                                        </label>
                                                    </div>
                                                    <div className="button">
                                                        <input
                                                            type="radio"
                                                            id="a7520"
                                                            name="check-substitution-240"
                                                        />
                                                        <label className="btn btn-default" htmlFor="a7520">
                                                            8
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="row">
                                                    <div className="col-sm-6 mb-3">
                                                        <div className="layout_tabs_main_chile">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="exampleInputEmail1"
                                                                    className="form-label"
                                                                >
                                                                    Lead Time: <sup>*</sup>
                                                                </label>
                                                                <select className="w-100 Layer_custum_css">
                                                                    <option>5 Working</option>
                                                                    <option>1 Working</option>
                                                                    <option>3 Working</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mb-3">
                                                        <label
                                                            htmlFor="exampleInputEmail1"
                                                            className="form-label"
                                                        >
                                                            Quantity: <sup>*</sup>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            defaultValue={1}
                                                            name="from"
                                                            oninput=""
                                                            className="form__field Quantity Layer_custum_css"
                                                        />
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <div className="order_comment">
                                                            <svg
                                                                width="20px"
                                                                height="20px"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M22 20H2V18H3V11.0314C3 6.04348 7.02944 2 12 2C16.9706 2 21 6.04348 21 11.0314V18H22V20ZM5 18H19V11.0314C19 7.14806 15.866 4 12 4C8.13401 4 5 7.14806 5 11.0314V18ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z" />
                                                            </svg>
                                                            <p className="ms-2">
                                                                Order Price is the landed cost of goods at the
                                                                location included in the invoice. Please keep a buffer
                                                                day beyond our committed delivery date to mitigate
                                                                downstream delays.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 mb-3">
                                                <div className="layout_tabs_main_chile">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="exampleInputEmail1"
                                                            className="form-label"
                                                        >
                                                            Stencil Data: <sup>*</sup>
                                                        </label>
                                                        <div id="wrapper" className="dropify-wrapper">
                                                            <input
                                                                type="file"
                                                                name="f-img"
                                                                className="form-control"
                                                            />
                                                            <div className="dropify-message">
                                                                <div className="dropify-message-1">
                                                                    <svg
                                                                        width="18px"
                                                                        height="18px"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                                                    </svg>
                                                                    <p>Drag and drop a file here or click</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                        <div className="col-12">
                          <div className="add_to_card">
                            <button
                              type="submit"
                              className="btn btn-primary add-to-card"
                              onClick={(e) => {
                                addcart(e);
                              }}
                            >
                              Add to Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="layout_tabs_main">
                      <div className="calculated_price_heading">
                        <h5 className="text-center w-100 text-green fontw-600 mb-0">
                          Calculated Price (₹)
                        </h5>
                      </div>
                      <div className="stencil_calculated_price calculated_price_padding">
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Lead Time:</label>
                          <label className="price-content">
                            {stencilWorkingDay}
                          </label>
                        </div>
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Order Value:</label>
                          <label id="OrderValue" className="price-content">
                            {selectedStencilVeri.price}
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Tax(%):</label>
                          <label id="OrderTax" className="price-content">
                            18
                          </label>
                        </div>
                        <hr className="price-hr" />
                        <div className="d-flex justify-content-between">
                          <label className="price-title">Total Price:</label>
                          <label
                            id="TotalOrderPrice"
                            className="price-content total-price"
                          >
                            {(
                              (parseInt(selectedStencilVeri.price) / 100) * 18 +
                              parseInt(selectedStencilVeri.price)
                            ).toFixed(2)}
                          </label>
                        </div>
                        <hr className="price-hr" />{" "}
                        <div className="d-flex justify-content-between">
                          <label className="price-title">
                            Tentative Shipment Date:
                          </label>{" "}
                          <label id="ShipmentDate" className="price-content">
                            {new Date(
                              new Date().setDate(
                                new Date().getDate() +
                                  parseInt(selectedStencilVeri.days)
                              )
                            )
                              .toISOString()
                              .slice(0, 10)}
                          </label>
                        </div>{" "}
                        <hr className="price-hr" />
                      </div>
                      <div>
                        {/* <h5 className="text-center w-100 text-green fontw-600">
                                                Alternatives
                                            </h5> */}
                        {/* <div className="calculator-table calculated_price_padding">
                                                <div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Lead Time:</label>{" "}
                                                        <label id="A1WDays" className="price-content">
                                                            7 WD
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">PCB Layout:</label>{" "}
                                                        <label id="A1OrderValue" className="price-content">
                                                            5250
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Tax(%):</label>{" "}
                                                        <label id="alttax1" className="price-content">
                                                            18
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Total Price:</label>{" "}
                                                        <label
                                                            id="A1Totalvalue"
                                                            className="price-content total-price"
                                                        >
                                                            6195
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="price-hr" />
                                            <div className="calculator-table calculated_price_padding">
                                                <div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Lead Time:</label>{" "}
                                                        <label id="A2WDays" className="price-content">
                                                            10 WD
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">PCB Layout:</label>{" "}
                                                        <label id="A2OrderValue" className="price-content">
                                                            3500
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Tax(%):</label>{" "}
                                                        <label id="alttax2" className="price-content">
                                                            18
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <label className="price-title">Total Price:</label>{" "}
                                                        <label
                                                            id="A2Totalvalue"
                                                            className="price-content total-price"
                                                        >
                                                            4130
                                                        </label>
                                                    </div>
                                                    <div className="d-flex justify-content-end fix-validation-text">
                                                        <label className="text-red" style={{ fontSize: 10 }}>
                                                            * WD = Working Days
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="tab-pane fade"
              id="pills-contact-pcs"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <form>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="layout_tabs_main mb-4">
                      <div className="row">
                        <div className="col-sm-4 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                PCB Name: <sup>*</sup>
                              </label>
                              <input
                                type="text"
                                className="Layer_custum_css"
                                id="exampleInputtext"
                                placeholder="PCB Name"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-2">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Lead Time: <sup>*</sup>
                          </label>
                          <div className="size_active">
                            <div className="button">
                              <input
                                type="radio"
                                id="a2510"
                                name="check-substitution-2311"
                              />
                             
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Quantity: <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            defaultValue={1}
                            name="from"
                            oninput=""
                            className="form__field Quantity Layer_custum_css"
                          />
                        </div>
                        <div className="col-sm-6 mb-3">
                          <div className="layout_tabs_main_chile">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Bill of Material: <sup>*</sup>
                              </label>
                              <div id="wrapper" className="dropify-wrapper">
                                <input
                                  type="file"
                                  name="f-img"
                                  className="form-control"
                                />
                                <div className="dropify-message">
                                  <div className="dropify-message-1">
                                    <svg
                                      width="18px"
                                      height="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                    </svg>
                                    <p>Drag and drop a file here or click</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-3 d-flex align-items-center justify-content-center">
                          <div className="login_inqury">
                            <h4>
                              Please <a href="#">Login</a> to place an inquiry.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Service;
