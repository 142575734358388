import logoLeft from "./images/RioSH-Final-01-1.png";

import about1 from "./images/about-1.png";
import img1 from "../assets/img/images-slider.jpg";

import client_1 from "./images/clients/client-1.png";
import client_2 from "./images/clients/client-2.png";
import client_3 from "./images/clients/client-3.png";
import client_4 from "./images/clients/client-4.png";
import client_5 from "./images/clients/client-5.png";
import client_6 from "./images/clients/client-6.png";
// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/autoplay";
import App from "../App";

import AOS from "aos";
import "aos/dist/aos.css";
import "./home.css";

import Countup from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import { useContext, useEffect, useState } from "react";
import Header from "./Header";
// import AOS from '../assets/vendor/aos'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { isCompositeComponent } from "react-dom/test-utils";
import ReactHtmlParser from "react-html-parser";
import context from "./mycontext";
const APP_URL = "https://www.admin.diaclabs.com/";

function Home() {

  let sliderSettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  };

  const [conterOn, setCounter] = useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  let ImgUrl = APP_URL + "image/";

  //THIS FOR SLIDER IMAGE DAYNAMIC.........
  const [sliderimg, setSlider] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const apiUrl = APP_URL + "getAllSlider";

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setSlider(data.message);
        // console.log(data.message);
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // ..........................!

  // THIS IS FOR VISION DAYNAMIC............
  const [vision, setVision] = useState([]);
  useEffect(() => {
    const apiUrl = APP_URL + "getAllVision"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setVision(data.message);
        // console.log(data)

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // .......

  var a = 0;

  //THIS IS FOR TESTIMONIALS ..........
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllIndustries"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setIndustries(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // ............

  const [testimonial, setTesti] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllTestimonal"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setTesti(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  // THIS IS FOR ABOUT US SECTION

  const [aboutus, setAbout] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAboutUs"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // ..................

  // THIS IS FOR CLINT SECTION

  const [client, setClient] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllClient"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setClient(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // ..........

  //THIS IS FOR REVOLITION  SECTION

  const [Revolution, setRevolution] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllRevolution"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setRevolution(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  // ............

  // THIS IS FOR ELECTRONIC SECTION
  const [electronic, setElectronic] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllElectronic"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setElectronic(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  // ........

  // THIS IS FOR PILL TAB SECTIONS
  const [layer, setlayer] = useState([]);
  console.log(layer, "layerrrr");

  useEffect(() => {
    const apiUrl = APP_URL + "getAllPcbLayoutLayer"; // Replace this with your API endpoint URL
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setlayer(data.message);
        // console.log(apiUrl);/

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  });

  const [components, setComponents] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllPcbLayoutComponent"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setComponents(data.message);
        // console.log(data.message)
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  const [variantPrice, setVeriantPrice] = useState(0);
  const [stencilVariant, setStencilVar] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllStencilVariants"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setStencilVar(data.message);
        // console.log(data.message)
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  const [stencilSizePrice, setStencilSizePrice] = useState(0);
  const [stencilSize, setStencilSize] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAllStencilSize"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setStencilSize(data.message);
        // console.log(data);
        //   console.log(data.message)
        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  return (
    <>
      <section className="App">
        {/* End Header */}
        {/* ======= Hero Section ======= */}
        {/* <div id="carouselExampleAutoplaying"
        className="carousel slide " style={{position:'relative', top:'1.3rem'}}
        data-bs-ride="carousel" >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={0}
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={1}
            aria-label="Slide 2"
          />
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={2}
            aria-label="Slide 3"
          />
          <button
            type="button"
            
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={3}
            aria-label="Slide 4"
          />
        </div>
        <div className="carousel-inner d-none" >
          {<div className="carousel-item active">
            <img
            src={img1}
            className="d-block w-100"
            alt="..."
            />
          </div> }
          {<div className="carousel-item active">
            <img
              src={img1}
              className="d-block w-100"
              alt="..."
              />
            </div> } */}
        {/* {sliderimg.map((item) => {

            return (
              <div className="carousel-item active" key={item.id} >
                <img src={ImgUrl + item.image} className="d-block w-100" alt="no no nooooooo" style={{ height: '500px' }} />
              </div>
            )
          })} */}

        {/* </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden "  >Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}
        {/* End Hero */}
        {/* ======= tabs section ======= */}
        {/* <section className="tabs_section_bg_color">
        <div className="container">
          <div class="row">
          <div class="col-12 d-flex justify-content-center">
            dfdsfdf
         </div> 
         </div>
        </div>
      </section> */}

        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner" style={{ position: "relative", 'cursor': 'pointer' }}>
            {sliderimg.map((item) => {
              a++;
              var classnew = a == 1 ? "carousel-item active" : "carousel-item";

              return (
                <div className={classnew} key={item.id} onClick={() => item.url && (window.location = item.url)}>
                  <img
                    src={ImgUrl + item.image}
                    className="d-block w-100"
                    alt="no no nooooooo"
                    style={{ height: "610px" }}
                  />
                </div>
              );
            })}
          </div>
         
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
            style={{'height': 'fit-content', 'margin':'auto'}}
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
            style={{'height': 'fit-content', 'margin':'auto'}}
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

         {/* <section className="tabs1" id="new">
          <div className="container ">
            <div class="row">
              <div class="col-md-1 "></div>
              <div class="col-md-12">
                <div class="nwe10">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="home-tab-pane"
                        aria-selected="true"
                      >
                        PCB Layout
                      </button>
                    </li>
                    <li class="c" role="presentation">
                      <button
                        class="nav-link"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="profile-tab-pane"
                        aria-selected="false"
                      >
                        PCB Stencil
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="component-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#component-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="component-tab-pane"
                        aria-selected="false"
                      >
                        Component Sourcing
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="assembly-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#assembly-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="assembly-tab-pane"
                        aria-selected="false"
                      >
                       PCB Assembly
                      </button>
                    </li> 
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="fabrication-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#fabrication-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="fabrication-tab-pane"
                        aria-selected="false"
                      >
                        PCB Fabrication 
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home-tab-pane"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      tabindex="0">

                      <div className="container">
                        <div className="row  d-flex align-items-center">
                          <div className="col-md-4">
                            <div>
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Layer: <sup>*</sup>
                              </label>
                              <select class="w-100 Layer_custum_css">
                                {layer.map((item) => {
                                  return <option>{item.title}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                No. of Components: <sup>*</sup>
                              </label>
                              <select class="w-100 Layer_custum_css">
                                {" "}
                                {components.map((item) => {
                                  console.log(item);
                                  return (
                                    <option value={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          

                          <div className="col-md-4">
                            <button className="button button--green">
                              <Link to="/service">Instant quote</Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="profile-tab-pane"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                      tabindex="0"
                    >
                      <div className="row  d-flex align-items-center">
                        <div className="col-md-4">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Stencil Side: <sup>*</sup>
                            </label>
                            <select class="w-100 Layer_custum_css">
                              {stencilSize.map((item) => {
                                return (
                                  <option value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Stencil Variants: <sup>*</sup>
                            </label>
                            <select class="w-100 Layer_custum_css">
                              {" "}
                              {stencilVariant.map((item) => {
                                // console.log(item,"varieants`")
                                return (
                                  <option value={item.price}>
                                    {item.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <button className="button button--green ">
                            {" "}
                            <Link to="/service" id="btn">
                              Instant quote
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="component-tab-pane"
                      role="tabpanel"
                      aria-labelledby="component-tab"
                      tabindex="0"
                    >
                      <div className="row  d-flex align-items-center">
                        <div className="col-md-4">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Quantity: <sup>*</sup>
                            </label>
                            <input
                                  type="text"
                                  // name="f-img"
                                  className="form-control"
                                  
                                />
                            
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Lead time: <sup>*</sup>
                            </label>
                            <select class="w-100 Layer_custum_css">
                              {" "}
                              {stencilVariant.map((item) => {
                                // console.log(item,"varieants`")
                                return (
                                  <option value={item.price}>
                                    {item.title}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <button className="button button--green">
                            {" "}
                            <Link to="/service" id="btn">
                              Instant quote
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="assembly-tab-pane"
                      role="tabpanel"
                      aria-labelledby="assembly-tab"
                      tabindex="0"
                    >
                      <div className="row  d-flex align-items-center">
                       
                        <div className="col">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Dimension: <sup>*</sup>
                                                </label>
                                                <div className="Dimensions_input d-flex align-items-center">
                                                    <input type="text" defaultValue={5} name="f" />
                                                    <span>X</span>
                                                    <input type="text" defaultValue={6} name="ff" />
                                                    <span>MM</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Quantity: <sup>*</sup>
                                                </label>
                                               <input type="text" className="form-control"  />
                                            </div>



                                          
                                             <div className="col">
                            <div>
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Layer: <sup>*</sup>
                              </label>
                              <select class="w-100 Layer_custum_css">
                                {layer.map((item) => {
                                  return <option value={item.id}>{item.title}</option>;
                                })}
                              </select>
                            </div>
                          </div>


                      
                        <div className="col">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Assembly slide: <sup>*</sup>
                            </label>
                            <select class="w-100 Layer_custum_css">
                              {" "}
                              <option value="">top</option>
                              <option value="">bottom</option>
                            </select>
                          </div>
                        </div>

                        <div className="col">
                          <button className="button button--green">
                            {" "}
                            <Link to="/service" id="btn">
                              Instant quote
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="fabrication-tab-pane"
                      role="tabpanel"
                      aria-labelledby="febrication-tab"
                      tabindex="0"
                    >
                      <div className="row  d-flex align-items-center">
                       
                        <div className="col">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Dimension: <sup>*</sup>
                                                </label>
                                                <div className="Dimensions_input d-flex align-items-center">
                                                    <input type="text" defaultValue={5} name="f" />
                                                    <span>X</span>
                                                    <input type="text" defaultValue={6} name="ff" />
                                                    <span>MM</span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Quantity: <sup>*</sup>
                                                </label>
                                               <input type="text" className="form-control"  />
                                            </div>



                                          
                                             <div className="col">
                            <div>
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Layer: <sup>*</sup>
                              </label>
                              <select class="w-100 Layer_custum_css">
                                {layer.map((item) => {
                                  return <option>{item.title}</option>;
                                })}
                              </select>
                            </div>
                          </div>


                      
                        <div className="col">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Assembly slide: <sup>*</sup>
                            </label>
                            <select class="w-100 Layer_custum_css">
                              {" "}
                              <option value="">top</option>
                              <option value="">bottom</option>
                            </select>
                          </div>
                        </div>

                        <div className="col">
                          <button className="button button--green">
                            {" "}
                            <Link to="/service" id="btn">
                              Instant quote
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1 "></div>
            </div>
          </div>
          <div></div>
         
        </section> */}





        <main id="main">
          <section className="About">
            <div className="container">
              {aboutus.map((item) => {
                return (
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                      <div className="section-title">
                        <h2>About us</h2>

                        <h3>{item.title}</h3>
                        <p>{ReactHtmlParser(item.description)}</p>
                        
                   
                        {/* <div className="row icon d-flex align-items-center">
                          <div className="col-md-2">
                            <i className="bi bi-shield-shaded" />
                          </div>
                          <div className="col-md-5">
                            <h4>Experience</h4>
                            <p>{item.exp}</p>
                          </div>
                        </div> */}

                        {/* <div className="button-wrapper mt-4" data-tippy-content="Click to copy button 71">
                          <button className="button-71" role="button">
                            Explore more
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={ImgUrl + item.image} />
                    </div>
                  </div>
                );
              })}
            </div>
          </section>



          <section id="featured-services" className="featured-services">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title1">
                <h3>
                  A Digital Revolution in <span>Manufacturing</span>
                </h3>
                <p>
                  Experience the power of a fully integrated platform with Diac
                  Labs
                </p>
              </div>
              <div className="row">
                {Revolution.map((item) => {
                  return (
                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                      <div
                        className="icon-box aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <img
                          src={ImgUrl + item.image}
                          alt=""
                          style={{ width: "25%" }}
                        />
                        <h4 className="title">
                          <a href="">{item.title}</a>
                        </h4>
                        <p className="description">{item.description} </p>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
           
              <h4 className="title"><a href="">PCB Fabrication</a></h4>
              <p className="description">Choose gerber, layer count, material, surface finish, and quantity. Diac Labs ensures impeccable fabrication, even for the most intricate designs. Capabilities includes 1-24 Layers of PCB Manufacturing. </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
             
              <h4 className="title"><a href="">Electronic Components sourcing</a></h4>
              <p className="description">Provide your BOM and quantities. Our platform generates quotations along with precise delivery timelines. Genuine, Source traceable and latest date codes. </p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
            
              <h4 className="title"><a href="">Stencil Manufacturing</a></h4>
              <p className="description">Achieve perfection in PCB assembly. Our stencils are crafted for precision, leading to seamless soldering and reduced manufacturing errors. Different types of stencils are available with frame and with out frame stencil</p>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">
            
              <h4 className="title"><a href="">PCB Assembly</a></h4>
              <p className="description">Diac Labs blends automation and expertise for accurate and efficient assembly. Capabilities includes complete automated SMD and SMT assembly along with BGA assembly.</p>
            </div>
          </div> */}
              </div>
            </div>
          </section>

          <section className="Customer ">
            <div className="container">
              <div className="section-title">
                <h3>
                  Advantages of Choosing Diac Labs as your Electronic
                  Manufacturing Partner{" "}
                </h3>
                <p>
                  When you choose Diac Labs, you're choosing unmatched
                  advantages:
                </p>
              </div>

              <div className="row">
                {vision.map((item) => {
                  return (
                    <div className="col-md-4 mt-5 p-2">
                      <div className="box1">
                        <img
                          src={ImgUrl + item.image}
                          style={{ width: "25%" }}
                        />
                        <p className="text-center">{item.title}</p>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section id="services" className="services mt-5">
            <div className="container aos-init aos-animate" data-aos="fade-up">
              <div className="section-title1">
                <h3>
                  A Single Platform For All Your Electronic Manufacturing
                  Services
                </h3>
              </div>

              <div className="row">
                {electronic.map((item) => {
                  return (
                    <div
                      className="col-lg-4 mt-5 col-md-6 d-flex align-items-stretch aos-init aos-animate"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    >
                      <div className="icon-box">
                        <img
                          src={ImgUrl + item.image}
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <h4>
                         {item.title}
                        </h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              
              <h4><a href="">Instant Automated Quoting</a></h4>
              <p>We understand the importance of quick decisions, and that's why we provide instant, transparent quotes. No more waiting around for estimates; you'll know the cost upfront, allowing you to make informed choices without any delays.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              
              <h4><a href="">Rapid Turnaround Time</a></h4>
              <p>Time is of the essence, and we take this seriously. Once your order is confirmed, our rapid production process ensures that you get your products promptly. Whether you have tight deadlines or need results quickly, we've got you covered.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
            
              <h4><a href="">Live Order Tracking</a></h4>
              <p>We believe in transparency, and that's why we provide a real-time order tracking system. You'll never be in the dark about the status of your project. Stay updated as your order </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              
              <h4><a href="">Secure Payments</a></h4>
              <p>our trust is essential to us, and we take the security of your financial information seriously. Our secure payment system ensures that your transactions are safe, </p>
            </div>
          </div> */}
              </div>
            </div>
          </section>

          <section className="Industries pt-2 pb-3 mt-4">
            <div className="container">
              <div className="section-title">
                <h1>Domain Expertise</h1>
              </div>
              <div className="row">
                {industries.map((item) => {
                  return (
                    <div className="col-md-3">
                      <div className="box-3">
                        <img src={ImgUrl + item.image} />
                        <h6>{item.title}</h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          {/* ======= Counts Section ======= */}
          {/* <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="count-box">
                  <i className="bi bi-emoji-smile" />
                  <ScrollTrigger onEnter={() => setCounter(true)} onExit={() => setCounter(false)}>
                    <span
                    // data-purecounter-start={0}
                    // data-purecounter-end={232}
                    // data-purecounter-duration={1}
                    // className="purecounter"

                    >
                      {conterOn && <Countup start={0} end={232} duration={1} delay={0} />}


                    </span>
                  </ScrollTrigger>
                  <p>Happy Clients</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div className="count-box">
                  <i className="bi bi-journal-richtext" />
                  <ScrollTrigger onEnter={() => setCounter(true)} onExit={() => setCounter(false)}>
                    <span
                    // data-purecounter-start={0}
                    // data-purecounter-end={232}
                    // data-purecounter-duration={1}
                    // className="purecounter"

                    >
                      {conterOn && <Countup start={0} end={521} duration={1} delay={0} />}


                    </span>
                  </ScrollTrigger>
                  <p>Projects</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-headset" />
                  <ScrollTrigger onEnter={() => setCounter(true)} onExit={() => setCounter(false)}>
                    <span
                    // data-purecounter-start={0}
                    // data-purecounter-end={232}
                    // data-purecounter-duration={1}
                    // className="purecounter"

                    >
                      {conterOn && <Countup start={0} end={1463} duration={1} delay={0} />}
                    </span>
                  </ScrollTrigger>
                  <p>Hours Of Support</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="bi bi-people" />
                  <ScrollTrigger onEnter={() => setCounter(true)} onExit={() => setCounter(false)}>
                    <span
                    // data-purecounter-start={0}
                    // data-purecounter-end={232}
                    // data-purecounter-duration={1}
                    // className="purecounter"

                    >
                      {conterOn && <Countup start={0} end={15} duration={1} delay={0} />}


                    </span>
                  </ScrollTrigger>
                  <p>Hard Workers</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
          {/* End Counts Section */}
          {/* ======= Testimonials Section ======= */}
          <section id="testimonials" className="testimonials mt-5">
            <div className="container" data-aos="zoom-in ">
              <div
                className="testimonials-slider "
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <Slider {...sliderSettings}>
                  {testimonial.map((item) => {
                    return (
                      <div className="swiper-slide">
                        <div className="testimonial-item">
                          <img
                            src={ImgUrl + item.image}
                            className="testimonial-img"
                            alt=""
                          />
                          <h3>{item.title}</h3>
                          <h4>{item.desination}</h4>
                          <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left" />
                            {item.description}
                            <i className="bx bxs-quote-alt-right quote-icon-right" />
                          </p>
                        </div>
                      </div>
                    );
                  })}
                  {/* End testimonial item */}
                </Slider>
                {/* End testimonial item */}

                <div className="swiper-pagination" />
              </div>
            </div>
          </section>
          {/* End Testimonials Section */}
          <section id="clients" className="clients section-bg">
            <div className="container aos-init aos-animate" data-aos="zoom-in">
              <div className="row">
                {client.map((item) => {
                  return (
                    <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                      <img
                        src={ImgUrl + item.image}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </main>
        {/* End #main */}

        {/* ======= Footer ======= */}
        {/* <footer id="footer">
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <p>
                  Tamen quem nulla quae legam multos aute sint culpa legam noster
                  magna
                </p>
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" defaultValue="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>

      </footer> */}

        {/* End Footer */}
        <div id="preloader">
          <div className="preloader-1"> {/* <img src={logoLeft}/>{" "} */}</div>
        </div>
        <a
          href="#"
          className="back-to-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short" />
        </a>
      </section>
    </>
  );
}

export default Home;
