import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import SingleProducts from './pages/SingleProducts';
import ProductCatogery from './pages/ProductCatogery';
import ProductSubCat from './pages/ProductSubCat';
import Header from './pages/Header';
import About from './pages/About';
import Reg from './pages/Regsitration';
import Login from './pages/Login';
import ForgetPass from './pages/forget_pass';

import { LoginContext } from './LoginContext';
import { useState, useEffect } from "react"
import Cart from './pages/Cart';
import ProductList from './pages/ProductList';
import Service from './pages/Serviceold';
import Servicesingle from './pages/Servicesingle';
import Footer from './pages/Footer';
import Reg2 from './pages/Reg2';
import EmailVerify from './pages/EmailVerify';
import EmailRegister from './pages/EmailRegister';
import Dashboard from './pages/Dashbord';
import UserProfile from './pages/UserProfile';
import DisclaimerPlicy from './pages/FooterContent/DisclaimerPlicy';
import Termspolicy from './pages/FooterContent/Termspolicy';
import Shipingpolicy from './pages/FooterContent/Shippingpolicy';
import Privacypolicy from './pages/FooterContent/Privacypolicy';
import Cookiespolicy from './pages/FooterContent/Cookiespolicy';
import Refundpolicy from './pages/FooterContent/Refundpolicy';
import Chekout from './Chekout';
import ScrollToTop from './ScrollToTop';
import Edit from './pages/Edit';
import Loader from './pages/Service/Loader';
import Payment from './Payment';
import EditPage from './EditPage';
import Orders from './pages/user/orders';
import ShippedOrders from './pages/user/ShippedOrders';
import Order_exception from './pages/user/order_exception';

import Enquiries from './pages/user/enquiries';
import EmailManage from './pages/user/emailManage';
import Invoice from './pages/user/invoice';
import Unpaid_invoices from './pages/user/unpaid_invoices';
import TrackEnquery from './pages/user/trackEnquery';
import InvoiceDownload from './pages/user/invoicePdf';
import MyState from '../src/pages/myState';

window.APP_URL = "https://www.admin.diaclabs.com/";
window.APP_URL2 = "https://www.admin.diaclabs.com/";

//window.APP_URL = "http://localhost:4000/";
 //window.APP_URL2 = "http://localhost:4000/"; 


function App() {
  // const[cart,setCart] =useState([])
  // useEffect(()=>{
  // localStorage.setItem('cart',JSON.stringify(cart))
  // },[cart])

  return (
    <MyState>
      <Router>
        <ScrollToTop>
          <Header />
          <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route path='about' element={<About />}></Route>
            <Route path='/service' element={<Servicesingle />}></Route>
            <Route path='/service/:slug' element={<Servicesingle />}></Route>
            <Route path='/SingleProducts/:id' element={<SingleProducts />}></Route>
            <Route path='/productCatogery' element={<ProductCatogery />}></Route>
            <Route path='/subproductCatogery/:id' element={<ProductSubCat />}></Route>
            <Route path='/productList/:id' element={<ProductList />}></Route>
            <Route path='/registration' element={<Reg2 />}></Route>
            <Route path='/emailVerify' element={<EmailVerify />}></Route>
            <Route path='/emailVerify/Registration' element={<EmailRegister />}></Route>
            <Route path='/dashboard' element={<Dashboard />}></Route>
            <Route path='/userProfile' element={<UserProfile />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/ForgetPass' element={<ForgetPass />}></Route>
            <Route path='/cart' element={<Cart />}></Route>
            <Route path='/disclaimer' element={<DisclaimerPlicy />}></Route>
            <Route path='/termspolicy' element={<Termspolicy />}></Route>
            <Route path='/shiping' element={<Shipingpolicy />}></Route>
            <Route path='/privacypolicy' element={<Privacypolicy />}></Route>
            <Route path="/cookies" element={<Cookiespolicy />}></Route>
            <Route path="/refund" element={<Refundpolicy />}></Route>
            <Route path="/chekout" element={<Chekout />}></Route>
            <Route path="/loader" element={<Loader />}></Route>
            <Route path="/edit/:empid?" element={<Edit />}></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/editpage/:empid?" element={<EditPage />}></Route>
            <Route path="/orders" element={<Orders />}></Route>
            <Route path="/Order_exception" element={<Order_exception />}></Route>
            <Route path="/Enquiries" element={<Enquiries />}></Route>
            <Route path="/ShippedOrders" element={<ShippedOrders />}></Route>
            <Route path="/EmailManage" element={<EmailManage />}></Route>
            <Route path="/Invoice" element={<Invoice />}></Route>
            <Route path="/unpaid_invoices" element={<Unpaid_invoices />}></Route>
            <Route path="/TrackEnquery" element={<TrackEnquery />}></Route>
            <Route path="/InvoiceDownload" element={<InvoiceDownload />}></Route>
          </Routes>
          <Footer />
        </ScrollToTop>
      </Router>
    </MyState>
  );
}

export default App;

