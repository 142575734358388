import React, { useContext } from "react";
// import ServiceHeader from "./ServiceHeader";
import { useState } from "react";
import { useEffect } from "react";

import IMG from "../box-gray.png";
import { AiOutlineDelete } from "react-icons/ai";
import "../Chekout.css";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import ima from "../../src/empty-cart.png";
import myContext from "./mycontext";
import EasebuzzCheckoutButton from './user/EasebuzzCheckoutButton'; // Adjust the path accordingly
import Edit from './Edit'; // Adjust the path as needed

const APP_URL = "https://www.admin.diaclabs.com/";

const Cart = () => {
  const context = useContext(myContext);
  console.log(context);

  const { show, setshow } = context;
  console.log(show);

  const [layerCart, setlayerCart] = useState([]);
  const [StencilCart, setStencilCart] = useState([]);
  const [AssemblyCart, setAssemblyCart] = useState([]);

  const [TotalPrice, setTotalPrice] = useState([]);
  const [TotalPriceWithTax, setTotalPriceWithTax] = useState([]);

  const [TotalPrice_with_texStencil, setTotalPrice_with_texStencil] = useState([]);
  const [UserData, setUserData] = useState([]);
  const [User, setUser] = useState([]);
  const [CheckOut, setCheckOut] = useState([]);
  let navigate = useNavigate();
  const token = localStorage.getItem("token");
  const totalPrice = 0;
  const total_price_with_tex = 0;
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(APP_URL + "Get/Cart/data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setlayerCart(result.layoutData);
        setStencilCart(result.stencilData);
        setAssemblyCart(result.assemblyData);

        setTotalPrice(result.totalAmount);
        setTotalPriceWithTax(result.total_price_with_tex);
        console.log("Get ALll Data :: ", result);
        // alert('test')
        if (result.totalAmount == 0) {
          $('#CartNotEmpty').hide();
          $('#CartEmpty').show();
        } else {
          $('#CartNotEmpty').show();
          $('#CartEmpty').hide();
        }
      })
      .catch((error) => console.log("error", error));

    fetch(APP_URL + "GetuserAddress", requestOptions)
      .then((response) => response.json())
      .then((user_result) => {
        console.log("GetuserAddress ::: ", user_result);
        setUserData(user_result.message);
        setUser(user_result.message[0]);
      })
      .catch((error) => console.log("error", error));
  }, []);
  // console.log('getLayoutCartData :::::: ==== ' + totalPrice);


  function Handler(id, type) {
    // alert(id);
    var result = "Want to delete?";
    if (result) {
      //Logic to delete the item

      var myHeaders = new Headers();
      myHeaders.append("authorization", token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      let url;
      if (type === "layout") {
        url = APP_URL + "LayoutCartDataDelete/";
      } else if (type === "stencil") {
        url = APP_URL + "StencilCartDataDelete/";
      } else if (type === "assembly") {
        url = APP_URL + "AssemblyCartDataDelete/";
      }

      fetch(url + id, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // setlayerCart(result.message);
          console.log("result", result);
          $("#" + type + id).html("");
          window.location = '?';
          navigate("/cart");
        })
        .catch((error) => console.log("error", error));
    }
  }

  // const cart = true; 
  function checkout() {
    // const cart = false;
    setCheckOut('payNow');
    // $(".Cart").hide();
    // $(".Checkout").show();
  }
  
  function PayNow() {
    // alert('test');
    // var myHeaders = new Headers();
    // myHeaders.append("authorization", token);
    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };
    // fetch(APP_URL + "order/store", requestOptions)
    //   .then((response) => response.text())
    //   .then((orderResult) => {
    //     console.log("Order Result ::: ", orderResult);
    //     // setUserData(orderResult.message)
    //     alert("Successfully Order Placed.");
    //     // dashboard
    //     navigate("/dashboard");
    //   })
    //   .catch((error) => console.log("error", error));
  }

  return (
    <>
    <script src="https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"></script>

      <div className="container mt-2 ">
        <>
          {" "}
          <div
            className="row "
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="CartNotEmpty"
          >
            
            <div className="col-lg-8 Cart" id="Cart">
              <div className=""></div>
              {layerCart[0] ? (
                <div className="p-3">
                  <div
                    className="row "
                    style={{
                      border: "2px solid #f0f2f2",
                      borderRadius: "1rem",
                    }}
                  >
                    <div className="container" style={{ height: "2rem" }}>
                      <div
                        className="row"
                        style={{
                          border: "2px solid #f0f2f2",
                          borderRadius: "",
                          backgroundColor: "#f0f2f2",
                          borderTopLeftRadius: "0.8rem",
                          borderTopRightRadius: "0.8rem",
                          height: "3rem",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        {/* <div className="col-lg-1">
                                        <input type="checkbox"  style={{width:'2rem',fontSize:'2rem', height:'20px',width:'20px'}}/>
                                        </div> */}
                        <div className="col-lg-11">
                          <span>Cart PCB Layout</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block ">
                      <div
                        style={{
                          backgroundColor: "#f0f2f2",
                          position: "relative",
                          top: "3rem",
                          width: "66px",
                          height: "66px",
                          textAlign: "center",
                          right: "0.6rem",
                          justifyContent: "center",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={IMG}
                          alt=""
                          width={"30px"}
                          style={{ position: "relative", top: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-11 ">
                      <table class="table mt-4">
                        <thead
                          style={{
                            background: "#f0f2f2",
                            borderBottom: "red",
                            border: "whiite",
                          }}
                        >
                          <tr
                            style={{
                              background: "#f0f2f2",
                              borderBottom: "red",
                              border: "whiite",
                            }}
                          >
                            <th scope="col">Quantity</th>
                            <th scope="col">PCB Name</th>
                            <th scope="col">Layer</th>
                            <th scope="col">Components</th>
                            <th scope="col">Lead Time</th>
                            {/* <th scope="col">Price</th> */}
                            <th scope="col">Order Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {layerCart.map((item, index) => {
                            return (
                              // <option value={item.id}>{item.layer}</option>
                              <tr id={"layout" + item.id}>
                                {/* <th scope="row">1</th> */}
                                <td>{item.quantity}</td>
                                <td>{item.pcb_name}</td>
                                <td>{item.layer}</td>
                                <td>{item.components}</td>
                                <td>{item.lead_time} Days</td>
                                <td>₹ {item.price}</td>
                                {/* <td>₹ {item.total_price_with_tex}</td> */}
                                <td
                                  onClick={(e) => Handler(item.id, "layout")}
                                >
                                  {" "}
                                  <AiOutlineDelete
                                    size={20}
                                    color="orange"
                                  />{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div> </div>
              )}

              {StencilCart[0] ? (
                <div className="mt-2 p-3">
                  <div
                    className="row "
                    style={{
                      border: "2px solid #f0f2f2",
                      borderRadius: "1rem",
                    }}
                  >
                    <div className="container" style={{ height: "2rem" }}>
                      <div
                        className="row"
                        style={{
                          border: "2px solid #f0f2f2",
                          borderRadius: "",
                          backgroundColor: "#f0f2f2",
                          borderTopLeftRadius: "0.8rem",
                          borderTopRightRadius: "0.8rem",
                          height: "3rem",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        {/* <div className="col-lg-1">
                                        <input type="checkbox"  style={{width:'2rem',fontSize:'2rem', height:'20px',width:'20px'}}/>
                                        </div> */}
                        <div className="col-lg-11">
                          <span>Cart PCB Stencil</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block ">
                      <div
                        style={{
                          backgroundColor: "#f0f2f2",
                          position: "relative",
                          top: "3rem",
                          width: "66px",
                          height: "66px",
                          textAlign: "center",
                          right: "0.6rem",
                          justifyContent: "center",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={IMG}
                          alt=""
                          width={"30px"}
                          style={{ position: "relative", top: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-11 ">
                      <table class="table mt-4">
                        <thead
                          style={{
                            background: "#f0f2f2",
                            borderBottom: "red",
                            border: "whiite",
                          }}
                        >
                          <tr
                            style={{
                              background: "#f0f2f2",
                              borderBottom: "red",
                              border: "whiite",
                            }}
                          >
                            <th scope="col">Quantity</th>
                            <th scope="col">pcb_name</th>
                            <th scope="col">Stencil Variants</th>
                            <th scope="col">Stencil Sizes</th>
                            <th scope="col">Lead Time</th>
                            {/* <th scope="col">Price</th> */}
                            <th scope="col">Order Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {StencilCart.map((item, index) => {
                            return (
                              // <option value={item.id}>{item.layer}</option>
                              <tr id={"stencil" + item.id}>
                                {/* <th scope="row">1</th> */}
                                <td>{item.quantity}</td>
                                <td>{item.pcb_name}</td>
                                <td>{item.stencil_variants}</td>
                                <td>{item.stencil_sizes}</td>
                                <td>{item.lead_time} Days</td>
                                <td>₹ {item.price}</td>
                                {/* <td>₹ {item.total_price_with_tex}</td> */}
                                <td
                                  onClick={(e) => Handler(item.id, "stencil")}
                                >
                                  {" "}
                                  <AiOutlineDelete
                                    size={20}
                                    color="orange"
                                  />{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                // <div> test </div>

                <div> </div>
              )}
              {AssemblyCart[0] ? (
                <div className="mt-2 p-3">
                  <div
                    className="row "
                    style={{
                      border: "2px solid #f0f2f2",
                      borderRadius: "1rem",
                    }}
                  >
                    <div className="container" style={{ height: "2rem" }}>
                      <div
                        className="row"
                        style={{
                          border: "2px solid #f0f2f2",
                          borderRadius: "",
                          backgroundColor: "#f0f2f2",
                          borderTopLeftRadius: "0.8rem",
                          borderTopRightRadius: "0.8rem",
                          height: "3rem",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        {/* <div className="col-lg-1">
                                        <input type="checkbox"  style={{width:'2rem',fontSize:'2rem', height:'20px',width:'20px'}}/>
                                        </div> */}
                        <div className="col-lg-11">
                          <span>Cart PCB Assembly</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block ">
                      <div
                        style={{
                          backgroundColor: "#f0f2f2",
                          position: "relative",
                          top: "3rem",
                          width: "66px",
                          height: "66px",
                          textAlign: "center",
                          right: "0.6rem",
                          justifyContent: "center",
                          borderRadius: "50%",
                        }}
                      >
                        <img
                          src={IMG}
                          alt=""
                          width={"30px"}
                          style={{ position: "relative", top: "1rem" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-11 ">
                      <table class="table mt-4">
                        <thead
                          style={{
                            background: "#f0f2f2",
                            borderBottom: "red",
                            border: "whiite",
                          }}
                        >
                          <tr
                            style={{
                              background: "#f0f2f2",
                              borderBottom: "red",
                              border: "whiite",
                            }}
                          >
                            {/* <th scope="col">Quantity</th> */}
                            <th scope="col">PCB Name</th>
                            <th scope="col">Layer</th>
                            <th scope="col">PCB Thickness</th>
                            <th scope="col">PCB Dimensions</th>
                            <th scope="col">SMD Components Pads</th>
                            {/* <th scope="col">Price</th> */}
                            <th scope="col">Order Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AssemblyCart.map((item, index) => {
                            return (
                              // <option value={item.id}>{item.layer}</option>
                              <tr id={"assembly" + item.id}>
                                {/* <th scope="row">1</th> */}
                                {/* <td>{item.quantity}</td> */}
                                <td>{item.pcb_name}</td>
                                <td>{item.no_of_layers}</td>
                                <td>{item.pcb_thickness}</td>
                                <td>{item.pcb_dimensions}</td>
                                <td>{item.smd_components_pads}</td>
                                <td>₹ {item.price}</td>
                                {/* <td>₹ {item.total_price_with_tex}</td> */}
                                <td
                                  onClick={(e) =>
                                    Handler(item.id, "assembly")
                                  }
                                >
                                  {" "}
                                  <AiOutlineDelete
                                    size={20}
                                    color="orange"
                                  />{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <div> </div>
              )}

              {/* <div className="col"><button className="cart-button" >Modify</button></div> */}
            </div>

            <div
              className="col-lg-8 Checkout"
              id="Checkout"
              style={{ display: "none" }}
            >





              <div className=""></div>
              <div className="p-3">

                <div className="Address_management_box" key={User.id}>

                  <h5>{User.firstname}</h5>
                  <hr className="border_hr" />
                  <p>
                    <b>Address: </b> {User.address1}
                  </p>
                  <p>
                    <b>City: </b> {User.city}
                  </p>
                  <p>
                    <b>State: </b> {User.state}
                  </p>
                  <p>
                    <b>District: </b> {User.districk}
                  </p>
                  <p>
                    <b>Pincode: </b> {User.pincode}
                  </p>

                  <p>
                    <b>Country: </b> {User.country}
                  </p>
                  <hr className="border_hr" />
                  <div className="botton_d_e d-flex align-items-center">
                    <button
                      id="selectRegister"
                      type="submit"
                      className="button_1 bg_success mt-3 "
                    >
                      <a onClick={() => { Edit(User.id) }}> Edit</a>
                    </button>
                  </div>
                </div>

                {/* <hr></hr> */}
                <div className="cart">
                  <label htmlFor="exampleInputEmail1" className="form-label">Purchase Order Nr </label>
                  <input type="text" className="form-control" placeholder="Purchase Order Nr" name="f" id="Order_Nr" />
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="layout_tabs_main_chile">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Purchase Order Nr: <sup>*</sup>
                      </label>
                      <div id="wrapper" className="dropify-wrapper">
                        <input
                          type="file"
                          // name="f-img"
                          className="form-control"
                          id="Order_Nr"
                          accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"

                          // onChange={(e) => setUpload(e.target.files[0])}
                        />
                        <div className="dropify-message">
                          <div className="dropify-message-1">
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                            </svg>
                            <p>Drag and drop a file here or click</p>
                          </div>
                          {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className=""> */}
            <div
              class="layout_tabs_mains col-lg-4 mb-5 "
              style={{ border: "2px solid #f0f2f2", borderRadius: "1rem" }}
            >
              <div className="row">
                <div
                  class="calculated_price_heading"
                  style={{
                    backgroundColor: "#f0f2f2",
                    height: "3rem",
                    borderTopLeftRadius: "0.8rem",
                    borderTopRightRadius: "0.8rem",
                  }}
                >
                  <h5 class=" w-100 text-green fontw-600 mb-0">
                    Cart Total's
                  </h5>
                </div>
              </div>

              <div class="stencil_calculated_price calculated_price_padding">
                {/* <div class="d-flex justify-content-between">
                  <label
                    class="price-title"
                    style={{ fontWeight: "bold", background: "white" }}
                  >
                    Total Order Value
                  </label>
                  <label class="price-content" id="cal_Lead_Time"></label>
                </div> */}
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: "bold" }}>
                    Total Order Value:
                  </label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    {TotalPrice}
                  </label>
                </div>{" "}
                {/* <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: "bold" }}>
                    Tax Rate(%)
                  </label>
                  <label id="OrderValue" class="price-content">18%</label>
                </div>{" "} */}
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: "bold" }}>
                    Tax Price:
                  </label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    {TotalPriceWithTax - TotalPrice}
                  </label>
                </div>{" "}
                <hr></hr>
                <div class="d-flex justify-content-between">
                  <label class="price-title" style={{ fontWeight: "bold" }}>
                    Net Price:
                  </label>{" "}
                  <label id="ShipmentDate" class="price-content">
                    {TotalPriceWithTax}
                  </label>
                </div>{" "}
              </div>

              <div className="col ">
              {CheckOut === 'payNow' ? (
                 <EasebuzzCheckoutButton/>
                
              ) : (
                <>
                <button
                  className="cart-buttons Cart"
                  onClick={(e) => checkout()}
                >
                  Proceed to Checkout
                </button>
                </>
              )}

                {/* <button
                  className="ebz-checkout-btn cart-buttons Checkout"
                  style={{ display: "none" }}
                  onClick={(e) => PayNow()}
                >
                  Pay Now
                </button> */}
                
                {/* ) } */}
              </div>
              <div></div>
            </div>
            {/* </div> */}
          </div>{" "}
        </>

        <>
          {" "}
          <div class="container" id="CartEmpty">
            <main class="main">
              <div class="top-70"></div>{" "}
              <section class="section">
                <div class="container">
                  <div class="row">
                    <div className="col-lg-3"></div>
                    <div class="col-md-9">
                      <div class="empty-cart">
                        <img src={ima} /> <h4>Your Cart is Empty</h4>{" "}
                       {" "}
                        
                        <Link
                          to="/service"
                          class="button button--promo"
                          style={{ width: "23rem" }}
                        >
                          Continue shopping
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>{" "}
        </>
      </div>
    </>
  );
};

export default Cart;
