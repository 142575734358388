import custom_pcb_manufacturer from "./images/About/custom_pcb_manufacturer.png";
import light_on from "./images/About/light-on.png";
import design from "./images/About/design.png";
import small_business from "./images/About/small-business.png";
import Presentation from "./images/About/Presentation.jpg";
import Header from "./Header";
import IMG2 from "./img/online_pcb_fabrication.png";
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import imagess from "../presentation.png"
const APP_URL = "https://www.admin.diaclabs.com/";

function About() {
  var handleClick = (e) => {
    const navbar = document.getElementById("navbar");

    // Toggle the class 'navbar-mobile' on the element with id 'navbar'
    navbar.classList.toggle("navbar-mobile");

    // Toggle the class 'bi-list' and 'bi-x' on the clicked element
    e.target.classList.toggle("bi-list");
    e.target.classList.toggle("bi-x");
  };
  var handleClick = (e) => {
    // Get the element with id 'navbar'
    const navbar = document.getElementById("navbar");

    // Check if the 'navbar' element has the class 'navbar-mobile'
    if (navbar.classList.contains("navbar-mobile")) {
      e.preventDefault();

      // Toggle the class 'dropdown-active' on the next sibling of the clicked element
      e.target.nextElementSibling.classList.toggle("dropdown-active");
    }
  };



  const [aboutus, setAbout] = useState([]);

  useEffect(() => {
    const apiUrl = APP_URL + "getAboutUs/About"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  let ImgUrl = APP_URL + "image/";

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = APP_URL + "getAboutUs/About"; // Replace this with your API endpoint URL

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setAbout(data.message);

        setLoading(false); // Set loading state to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);
  return (
    <>
      <section className="container-fluid bg_img-12">
        <div className="container my-2">
          <div className="Buy_Enclosure text-white">
            <h1>About Us</h1>
            <div className="Buy Enclosure_Icon d-flex align-items-center">
              <a href="/">
                <svg
                  height="18px"
                  width="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 20.0001C20 20.5524 19.5523 21.0001 19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001ZM11 13.0001V19.0001H13V13.0001H11Z"
                    fill="rgba(247,247,247,1)"
                  ></path>
                </svg>
              </a>
              <span className="ms-2 mt-1 fw-bold">  Home</span>
            </div>
          </div>
        </div>
      </section>

      <section className="About">
        <div className="container" >
          {aboutus.map((item) => {
            return (
              <div className="row d-flex align-items-center" key={item.id}>
                <div className="col-md-12">
                  <div className="section-title">
                    {/* <h2>About us</h2> */}

                    <h3>{item.title}</h3>
                    <p>{ReactHtmlParser(item.description)}</p>

                    {/* <div className="row icon d-flex align-items-center">
                      <div className="col-md-2">
                        <i className="bi bi-shield-shaded" />
                      </div>
                      <div className="col-md-5">
                        <h4>Experience</h4>
                        <p>{item.exp}</p>
                      </div>
                    </div> */}

                    {/* <div
                      className="button-wrapper mt-4"
                      data-tippy-content="Click to copy button 71"
                    >
                      <button className="button-71" role="button">
                        Explore more
                      </button>
                    </div> */}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <img src={ImgUrl + item.image} />
                </div> */}
              </div>
            );
          })}
        </div>
      </section>
      {/*       
      <section className="About">
            <div className="container">
              {aboutus.map((item) => {
                return (
                  <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                      <div className="section-title">
                        <h2>About us</h2>

                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                        
                        <p>
                    We have resources and expertise to deliver quality solutions at
                    faster pace. We follow best practices, business processes,
                    proven methodologies and systematic approach while delivering
                    and designing solutions for our clients. We emphasize on
                    Intellectual Property law to protect business ideas of clients
                    from industry competition, will provide the life time design
                    support.
                  </p>
                        <div className="row icon d-flex align-items-center">
                          <div className="col-md-2">
                            <i className="bi bi-shield-shaded" />
                          </div>
                          <div className="col-md-5">
                            <h4>Experience</h4>
                            <p>{item.exp}</p>
                          </div>
                        </div>

                        <div
                          className="button-wrapper mt-4"
                          data-tippy-content="Click to copy button 71"
                        >
                          <button className="button-71" role="button">
                            Explore more
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <img src={ImgUrl + item.image} />
                    </div>
                  </div>
                );
              })}
            </div>
          </section> */}




      <section className="container-fluid bg--lgray--12 d-flex align-items-center justify-content-center">
        <div className="row">
          <div className="col-12">
            <div className="bg--lgray--12_text text-center">
              <h2 className="elementor-heading-title elementor-size-default mb-3">
                Do you have a great idea?
              </h2>
              <p className="ContactText text-center">
                From product sketching to technical inspiration, from
                feasibility to manufacturing we move your innovation into
                production.
              </p>
              {/* <a href="#" className="btn btn-primary">
                Contact Us
              </a> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div className="container py-5">
          <div className="RioSH_creates text-center">
            <h2 className="elementor-heading-title">
              DIACLABS creates irresistible{" "}
              <span className="color--green">products</span> and brands
            </h2>
          </div>
          <div className="row  py-5">
            <div className="col-lg-4">
              <div className="count_time_title_img text-center">
                <img src={light_on} className="w-25" alt="" />
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title mt-2 elementor-size-default">
                    Product Ideation
                  </h2>
                  <p>
                  Design your product with our seamless
                  PCB design services, Upload schematics and BOM, and instantly receive quotations based on the chosen layer count and capabilities ranging from 1-10 Layes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="count_time_title_img text-center">
                <img src={design} className="w-25" alt="" />
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title mt-2 elementor-size-default">
                    Prototyping
                  </h2>
                  <p>
                    No MOQ. Enter the quantiy and submit the technical details, Irrespective of MOQ, Diac team will share the quotation. Prototyping is easy with the DIAC labs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="count_time_title_img text-center">
                <img src={small_business} className="w-25" alt="" />
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title mt-2 elementor-size-default">
                    Market-ready Product
                  </h2>
                  <p>
                  Diac Labs streamlines the entire EMS
Production process by offering integrated services for layout design, fabrication, assembly, component procurement, and stencil production ensuting a seamless and efficient production workflow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="following_services">
                <h5 className="ms-3">
                  <strong>Diac Labs services:</strong>
                </h5>
                <div>&nbsp;</div>
                <ul>
                  <li>Electronic Product Design</li>
                  <li>PCB Design</li>
                  <li>IoT sensors &amp; hardware</li>
                  <li>Embedded Systems Design</li>
                  <li>Firmware programming</li>
                  <li>Electronic Prototypes</li>
                  <li>Electronic Manufacturing Services</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="following_services_img">
                <img src={imagess} className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="container-fluid">
        <div className="container py-5">
          <div className="RioSH_creates text-center">
            <h2 className="elementor-heading-title">
              Our<span className="color--green"> Success</span> Stories
            </h2>
            <p>
              Consumer products and Industrial automation is changing at a rapid
              pace, clients are looking for products with next-gen technologies
              to cope with that pace. Our team has helped clients by taking
              complete responsibility for a product. DIACLABS has delivered more
              than 100 projects in prototype designing and supported the same
              for production.{" "}
            </p>
          </div>
          <div class="container">
            <div class="experiance-info-tabs">
              <div class="experiance-tabs tabs-box">
                <ul class="tab-btns tab-buttons clearfix">
                  <li data-tab="#prod-html" class="tab-btn active-btn">

                    <span class="icon">
                      <img src={IMG2} alt="Online PCB Fabrication" className="imgs" />
                    </span>{" "} <br />
                    Customer Centricity
                  </li>{" "}
                  <li data-tab="#prod-bootstrap" class="tab-btn mt-box">
                    <span class="icon">
                      <img src={IMG2} alt="PCB Board Material" className="imgs" />
                    </span>{" "} <br />
                    Aim to Provide Dynamic Solutions of Standardized Quality
                  </li>{" "}
                  <li data-tab="#prod-css" class="tab-btn mt-box ">
                    <span class="icon">
                      <img src={IMG2} alt="PCB Manufacturer" className="imgs" />
                    </span>{" "} <br />
                    Passion to Empower Innovators
                  </li>{" "}
                  <li data-tab="#prod-php" class="tab-btn mt-box">
                    <span class="icon">
                      <img src={IMG2} alt="PCB Printing Service" className="imgs" />
                    </span>{" "} <br />
                    Growth Catalysts – Our People
                  </li>
                </ul>{" "}

              </div>
            </div>
          </div>
        </div>
      </section> */}
      <hr />
    </>
  );
}

export default About;
